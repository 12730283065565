import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import moment from "moment";
import Axios from "axios";
import Datetime from "react-datetime";
import { serverLocations } from "../../../../../utils/server-locations";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Firday", "Saturday"];

export const Settings = ({ league, setLeague }) => {
  const { register, handleSubmit, errors } = useForm({ mode: "all" });
  const [loading, setLoading] = useState(false);
  const [forfeitTeam, setForfeitTeam] = useState(true);
  const [playerDeadline, setPlayerDeadline] = useState(360);
  const [weekDays, setWeekDays] = useState([]);
  const [earliestDate, setEarliestDate] = useState(false);
  const [latestDate, setLatestDate] = useState(false);
  const [rescheduleType, setRescheduleType] = useState(false);
  const [minDate, setMinDate] = useState(false);
  const [maxDate, setMaxDate] = useState(false);
  const [location, setLocation] = useState(league.csgoServerLocation);
  const [isCSGO, setIsCSGO] = useState(false);
  const [setPlayersDeadlineHr, setSetPlayersDeadlineHr] = useState(0);
  const [setPlayersDeadlineMin, setSetPlayersDeadlineMin] = useState(0);

  const [tomorrow, setTomorrow] = useState(false);
  const [hideLeague, setHideLeague] = useState(true);
  const [showOn, setShowOn] = useState(false);
  const [showOnDate, setShowOnDate] = useState(false);

  // Use Effect
  useEffect(() => {
    calculateDateRanges();
    calculateWeakDays();
    setForfeitTeam(league.forfeitTeam);
    setPlayerDeadline(league.setPlayersDeadline ? league.setPlayersDeadline : 360);
    setRescheduleType(league.reschedule && league.reschedule.rescheduleType ? league.reschedule.rescheduleType : "NO_RESCHEDULE");
    setEarliestDate(
      league.reschedule && league.reschedule.earliestDate
        ? moment(league.reschedule.earliestDate)
        : league.startDate
        ? moment(league.startDate)
        : false
    );
    setLatestDate(
      league.reschedule && league.reschedule.latestDate
        ? moment(league.reschedule.latestDate).toISOString()
        : league.startDate
        ? moment(league.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString()
        : false
    );
    setIsCSGO(league.game?.name == "CS:GO");
    if (league.setPlayersDeadline && parseInt(league.setPlayersDeadline)) {
      setSetPlayersDeadlineHr(parseInt(league.setPlayersDeadline / 60));
      setSetPlayersDeadlineMin(parseInt(league.setPlayersDeadline % 60));
    }
    setHideLeague(league.hideLeague);
    setShowOnDate(league.showOnDate ? moment(league.showOnDate) : null);
    setShowOn(league.showOnDate ? true : false);
  }, [league]);

  // Calculate range bw current date and next weak end
  const calculateDateRanges = () => {
    let min = moment().subtract(1, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let max = moment().add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    let tomorrow = moment().subtract(1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    setMinDate(min);
    setMaxDate(max);
    setTomorrow(tomorrow);
  };

  const calculateWeakDays = async () => {
    let days = [];
    let currentDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    days.push({ date: currentDate, name: currentDate.format("dddd") });
    for (let i = 1; i <= 7; i++) {
      let date = moment(days[i - 1].date).add(1, "days");
      let date1 = date;
      days.push({ date, name: date1.format("dddd") });
    }
    setWeekDays(days);
  };
  // On match reschudule type selection
  const onMatchReschedule = (e) => {
    const { value } = e.target;
    if (value === "WEEK") {
      setEarliestDate(minDate.toISOString());
    }
    if (value === "INTERVAL") {
      setEarliestDate(
        league.reschedule && league.reschedule.earliestDate
          ? moment(league.reschedule.earliestDate)
          : league.startDate
          ? moment(league.startDate)
          : false
      );
      setLatestDate(
        league.reschedule && league.reschedule.latestDate
          ? moment(league.reschedule.latestDate).toISOString()
          : league.startDate
          ? moment(league.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString()
          : false
      );
    }
    setRescheduleType(value);
  };

  // On submit chagnes
  const onSubmit = async (data) => {
    if (data?.setPlayersDeadlineHr < 0 || data?.setPlayersDeadlineMin < 0) {
      toast.error("Invalid Set Player Deadline Time");
      return;
    }

    if (showOn && hideLeague) {
      if (moment(showOnDate).format("DD/MM/YYYY HH:mm:ss").valueOf() <= moment().format("DD/MM/YYYY HH:mm:ss").valueOf() || !showOnDate) {
        toast.error("Invalid Time");
        return;
      }
    }

    setLoading(true);
    const setPlayersDeadline = parseInt(setPlayersDeadlineHr * 60) + parseInt(setPlayersDeadlineMin);
    delete data.setPlayersDeadlineHr;
    delete data.setPlayersDeadlineMin;
    try {
      const formData = {
        setPlayersDeadline: setPlayersDeadline,
        forfeitTeam: data.forfietTeams,
        earliestDate: earliestDate,
        latestDate: latestDate,
        rescheduleType: rescheduleType,
        leagueFinished: data.leagueFinished,
        hideLeague: data.hideLeague,
        showOnDate: showOn && hideLeague ? moment(showOnDate).tz(league.timezone) : null,
      };
      if (isCSGO) {
        formData.csgoServerLocation = location;
      }

      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/setting/${league._id}`, formData);
      setLeague((league) => ({ ...league, ...formData, reschedule: { rescheduleType, earliestDate, latestDate } }));
      toast.success("Saved");
    } catch (error) {
      console.error(error);
      toast.error("There was a problem saving");
    } finally {
      setLoading(false);
    }
  };

  const formValid = !!(rescheduleType == "NO_RESCHEDULE" ? true : earliestDate && latestDate);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Forfeit Teams Failing To Set Player</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control as="select" ref={register()} id="forfietTeams" name="forfietTeams" defaultValue={league.forfeitTeam}>
                  <option value="doNothing">Do Nothing</option>
                  <option value="useLastPlayers">Use Last Players</option>
                  <option value="forfietTeam">Forfeit Team</option>
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Set Players Deadline (Hrs)</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="setPlayersDeadlineHr"
                name="setPlayersDeadlineHr"
                value={setPlayersDeadlineHr}
                onChange={(e) => setSetPlayersDeadlineHr(e.target.value)}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Set Players Deadline (Mins)</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="setPlayersDeadlineMin"
                name="setPlayersDeadlineMin"
                value={setPlayersDeadlineMin}
                onChange={(e) => setSetPlayersDeadlineMin(e.target.value)}
                onBlur={handleSubmit(onSubmit)}
                max={59}
                min={0}
              />
              <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Match Rescheduling</Form.Label>
              <Form.Control as="select" value={rescheduleType} id="matchReschedule" name="matchReschedule" onChange={onMatchReschedule}>
                <option value="NO_RESCHEDULE">No Reschduling</option>
                <option value="WEEK">Within The Week</option>
                <option value="INTERVAL">Within An Interval</option>
              </Form.Control>
            </Form.Group>
          </Col>

          {isCSGO && (
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>Server Location</Form.Label>
                <Form.Control
                  as="select"
                  value={location}
                  id="csgoServerLocation"
                  name="csgoServerLocation"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  onBlur={handleSubmit(onSubmit)}
                >
                  <option value={false} disabled>
                    Select Location
                  </option>
                  {serverLocations.map((serverLocation, index) => (
                    <option key={index} value={serverLocation.key}>
                      {serverLocation.value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {rescheduleType && rescheduleType === "WEEK" && (
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>Week Start</Form.Label>
                <Form.Control
                  as="select"
                  value={moment(latestDate).toISOString() || false}
                  id="weekStart"
                  name="weekStart"
                  onChange={(e) => {
                    setLatestDate(moment(e.target.value).toISOString());
                    setEarliestDate(minDate.toISOString());
                  }}
                >
                  <option value={false} disabled>
                    Select Weak Day
                  </option>
                  {weekDays.map((day, index) => (
                    <option key={index} value={moment(day.date).toISOString()}>
                      {day.name} ({moment(day.date).format("MMMM Do YYYY")})
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {rescheduleType && rescheduleType === "INTERVAL" && (
            <>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Earliest Date</Form.Label>
                  <Datetime
                    value={earliestDate ? moment(earliestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                    onChange={setEarliestDate}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm:ss"
                    isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Latest Date</Form.Label>
                  <Datetime
                    value={latestDate ? moment(latestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                    onChange={setLatestDate}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm:ss"
                    isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                  />
                </Form.Group>
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>League Finished?</Form.Label>
              <Form.Control
                as="select"
                ref={register({ required: true })}
                defaultValue={league.leagueFinished || false}
                id="leagueFinished"
                name="leagueFinished"
                onBlur={handleSubmit(onSubmit)}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.leagueFinished && errors.leagueFinished.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginTop: "0.5em" }}>
          <Col md={2}>
            <Form.Group>
              <Form.Check
                ref={register()}
                type="checkbox"
                name="hideLeague"
                checked={hideLeague}
                label="Hide League"
                onChange={() => {
                  setHideLeague(!hideLeague);
                }}
              />
            </Form.Group>
          </Col>

          {hideLeague && (
            <Col md={2}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  name="showOn"
                  checked={showOn}
                  label="Show On"
                  onChange={() => {
                    setShowOn(!showOn);
                  }}
                />
              </Form.Group>
            </Col>
          )}

          {hideLeague && showOn && (
            <Row>
              <Form.Label style={{ marginRight: "1em" }}>Date</Form.Label>
              <div style={{ marginTop: "-0.4em" }}>
                <Datetime
                  defaultValue={showOnDate ? showOnDate : ""}
                  onChange={setShowOnDate}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm:ss"
                  isValidDate={(currentDate) => currentDate.isAfter(tomorrow)}
                />
              </div>
            </Row>
          )}
        </Row>

        <Button type="submit" variant="success" disabled={!formValid}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default Settings;
