import React, { useState, useEffect } from "react";
import { Card, Nav, Button, Col, Row } from "react-bootstrap";
import Calendar from "./Calendar";
import Matches from "./Matches";
import CreateEditMatchModal from "./includes/CreateEditMatchModal";
import FilterMatches from "./includes/FilterMatches";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TableOverview from "./TableOverview";
import Information from "./Information";
import Settings from "./Settings";
import Prizes from "./Prizes";
import Rosters from "./Rosters";
import { TIMEZONES } from "../../../../../utils/timezones";

import ShuffleTeams from "../../SeasonTournaments/includes/ShuffleTeams";

const TabsManager = ({ league, setLeague, user }) => {
  const [activeTab, setActiveTab] = useState("Matches");
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [prefferedTimezone, setPrefferedTimezone] = useState(league.timezone || "Etc/UTC");

  useEffect(() => {
    setPrefferedTimezone(league.timezone || "Etc/UTC");
  }, [league.timezone]);

  const updateMatches = (callback) => {
    setLeague((l) => ({ ...l, matches: callback(l.matches) }));
  };

  const onEditMatch = (data) => {
    setShowMatchModal(true);
    setModalData(data);
  };

  const onCreateMatch = () => {
    setShowMatchModal(true);
    setModalData(false);
  };

  useEffect(() => {
    if (!(league && league.timezone && TIMEZONES.find(tz => tz.value == league.timezone))) {
      setPrefferedTimezone('Etc/UTC')
    }
  }, [league])

  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "Matches",
      content: (
        <Matches
          teams={league.teams}
          matches={filteredMatches}
          updateMatches={updateMatches}
          onEditMatch={onEditMatch}
          league={league}
          setLeague={setLeague}
          prefferedTimezone={prefferedTimezone}
          setPrefferedTimezone={setPrefferedTimezone}
        />
      ),
    },
    {
      name: "Calendar",
      content: <Calendar matches={filteredMatches} updateMatches={updateMatches} onEditMatch={onEditMatch} />,
      disabled: !user.state.admin && !user.state.permissions.user.matches ? "disabled-link" : "",
    },
    {
      name: "Seed Order",
      content: <ShuffleTeams tournament={league} />,
      disabled: !user.state.admin && !user.state.permissions.user.matches ? "disabled-link" : "",
    },
    {
      name: "Rosters",
      content: <Rosters tournament={league} />,
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
    {
      name: "Prizes",
      content: <Prizes league={league} setLeague={setLeague} />,
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
    {
      name: "Information",
      content: <Information tournament={league} setTournament={setLeague} />,
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
    {
      name: "Settings",
      content: <Settings tournament={league} setTournament={setLeague} />,
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name, disabled }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name} className={!user.state.admin && disabled}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {/* Show create match/filtering on matches/calendar only */}
          {["Matches", "Calendar"].includes(activeTab) && (
            <Row>
              <Col lg={12}>
                <FilterMatches matches={league.matches} setFilteredMatches={setFilteredMatches} />
              </Col>

              <CreateEditMatchModal
                show={showMatchModal}
                onHide={() => setShowMatchModal(false)}
                match={modalData}
                league={league}
                updateMatches={updateMatches}
                prefferedTimezone={prefferedTimezone}
              />
            </Row>
          )}

          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default TabsManager;
