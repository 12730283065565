import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import Axios from "axios";
import Datetime from "react-datetime";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { BsArrowLeftRight } from "react-icons/bs";
import { TIMEZONES } from "../../../../../../utils/timezones";

const CreateEditMatchModal = ({ show, onHide, match, updateMatches, prefferedTimezone }) => {
  const { id } = useParams();
  const [loadingSS, setLoadingSS] = useState(true);
  const [scoreSystems, setScoreSystems] = useState([]);
  const [league, setLeague] = useState(false);
  const [team1, setTeam1] = useState(false);
  const [vetoFlag, setVetoFlag] = useState(true);
  const [showVeto, setShowVeto] = useState(false);
  const [team2, setTeam2] = useState(false);
  const [selectedScoreSystem, setSelectedScoreSystem] = useState(false);
  const [bestOf, setBestOf] = useState(false);
  const [switchSides, setSwitchSides] = useState(true);
  const [date, setDate] = useState(match ? match.datetime : false);
  const [week, setWeek] = useState(1);
  const [round, setRound] = useState(1);
  const [customReschdule, setCustomReschdule] = useState(match && match.reschedule ? true : false);
  const [earliestDate, setEarliestDate] = useState(false);
  const [latestDate, setLatestDate] = useState(false);
  const [minDate, setMinDate] = useState(false);
  const [maxDate, setMaxDate] = useState(false);
  const [sideSelection, setSideSelection] = useState(false);
  const [broadcasted, setBroadcasted] = useState(false);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [localPrefferedTimezone, setLocalPrefferedTimezone] = useState(prefferedTimezone);

  useEffect(() => {
    setLocalPrefferedTimezone(prefferedTimezone);
  }, [prefferedTimezone]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scoreSystems`).then(({ data }) => {
      setScoreSystems(data);
      setLoadingSS(false);
    });

    Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}`).then(({ data }) => {
      setLeague(data);
      setEarliestDate(
        match && match.reschedule
          ? moment(match.reschedule.earliestDate).toISOString()
          : data.reschedule
          ? moment(data.reschedule.earliestDate).toISOString()
          : false
      );
      setLatestDate(
        match && match.reschedule
          ? moment(match.reschedule.latestDate).toISOString()
          : data.reschedule
          ? moment(data.reschedule.latestDate).toISOString()
          : false
      );
    });
  }, [id]);

  useEffect(() => {
    calculateDateRanges();
    setTeam1(match ? match.t1 && match.t1._id : false);
    setTeam2(match ? match.t2 && match.t2._id : false);
    setSelectedScoreSystem(match ? match.scoreSystem._id : false);
    setBestOf(match ? match.bestOf : false);
    setSwitchSides(match ? match.switchSides : true);
    setBroadcasted(match ? match.broadcasted : false);
    setSideSelection(match ? match.sideSelection : false);
    if (localPrefferedTimezone && localPrefferedTimezone !== "" && localPrefferedTimezone != undefined) {
      setDate(match ? moment(match.datetime).tz(localPrefferedTimezone).format("DD/MM/YYYY HH:mm") : false);
    } else if (league.timezone !== null && league.timezone !== "" && league.timezone != undefined) {
      setDate(match ? moment(match.datetime).tz(league.timezone).format("DD/MM/YYYY HH:mm") : false);
    } else {
      setDate(match ? moment(match.datetime).format("DD/MM/YYYY HH:mm") : false);
    }
    setWeek(match ? match.week : 1);
    setRound(match ? match.round : 1);
    setCustomReschdule(match && match.reschedule ? true : false);
    setShowVeto(match && league?.game?.shortName === "CSGO" && [1, 3, 5].includes(match.bestOf) ? true : false);
  }, [match, show]);

  const calculateDateRanges = () => {
    let min = moment(match.createdAt).subtract(1, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let max = moment(match.createdAt).add(7, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setMinDate(min);
    setMaxDate(max);
  };

  const getScoreSystemData = () => {
    if (selectedScoreSystem) {
      return scoreSystems.find(({ _id }) => _id === selectedScoreSystem);
    }

    return false;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const ifDateChanged = isDateChanged ? { datetime: moment(date, "DD/MM/YYYY HH:mm:ss").tz(localPrefferedTimezone, true) } : {};
      if (formValid) {
        const postData = {
          t1: team1,
          t2: team2,
          switchSides,
          sideSelection,
          broadcasted,
          scoreSystem: selectedScoreSystem,
          bestOf,
          ...ifDateChanged,
          week,
          round,
          reschedule: { earliestDate, latestDate },
        };
        if (
          (league.reschedule &&
            moment(earliestDate).isSame(league.reschedule?.earliestDate) &&
            moment(latestDate).isSame(league.reschedule?.latestDate)) ||
          !customReschdule
        ) {
          postData.reschedule = null;
        }

        let vetoEnable = showVeto ? vetoFlag : false;

        if (match) {
          const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${match._id}?isVeto=${vetoEnable}`, postData);
          updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
        } else {
          const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches?isVeto=${vetoEnable}`, {
            league: id,
            ...postData,
          });
          updateMatches((matches) => [...matches, data.newMatch]);
        }

        toast.success(`Successfully ${match ? "edited" : "created"} match!`);
        onHide();
        setIsDateChanged(false);
      }
    } catch (e) {
      console.log("error", e);
      toast.error("There was a problem processing this request 😞");
    }
  };

  const onDeleteMatch = async () => {
    const ids = [match._id];

    if (window.confirm("Are you sure you want to delete this match?")) {
      try {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/deleteMulti`, { ids });

        updateMatches((matches) => matches.filter(({ _id }) => !ids.includes(_id)));
        toast.success(`Successfully deleted ${ids.length} matches`);
        onHide();
      } catch (e) {
        toast.error("There was a problem deleting the selected matches");
      }
    }
  };

  const formValid = !!(
    team1 &&
    team2 &&
    selectedScoreSystem &&
    bestOf &&
    week &&
    round &&
    date &&
    (customReschdule && (!earliestDate || !latestDate) ? false : true)
  );

  const swapTeams = () => {
    let temp = team1;
    console.log(temp, match);
    setTeam1(team2);
    setTeam2(temp);
    temp = match.t1Score;
    match.t1Score = match.t2Score;
    match.t2Score = temp;
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        {match ? (
          <Modal.Title>
            <sub className="d-block mb-3">
              <small>Week {match.week}</small>
            </sub>
            {match.t1 ? match.t1.name : "BYE"} vs {match.t2 ? match.t2.name : "BYE"} (
            {moment.utc(match.datetime).tz(league?.timezone)?.format("DD/MM/YYYY @ HH:mm")})
          </Modal.Title>
        ) : (
          <Modal.Title>Create Match</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          {league && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Team 1</Form.Label>
                  <Form.Control as="select" value={team1 || "_DEFAULT_"} onChange={(e) => setTeam1(e.target.value)}>
                    <option value="_DEFAULT_" disabled>
                      Select team 1
                    </option>
                    {league.teams.map(
                      ({ team }) =>
                        team && (
                          <option key={team._id} value={team._id}>
                            {team.name}
                          </option>
                        )
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
              {match && (
                <Col sm={1} className="d-flex justify-content-end align-items-end">
                  <Form.Group>
                    <Button onClick={swapTeams} disabled={!team1 || !team2}>
                      <BsArrowLeftRight />
                    </Button>
                  </Form.Group>
                </Col>
              )}
              <Col>
                <Form.Group>
                  <Form.Label>Team 2</Form.Label>
                  <Form.Control as="select" value={team2 || "_DEFAULT_"} onChange={(e) => setTeam2(e.target.value)}>
                    <option value="_DEFAULT_" disabled>
                      Select team 2
                    </option>
                    {league.teams.map(
                      ({ team }) =>
                        team && (
                          <option key={team._id} value={team._id}>
                            {team.name}
                          </option>
                        )
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Form.Group>
            <Form.Label>Score System</Form.Label>
            <Form.Control as="select" value={selectedScoreSystem || "_DEFAULT_"} onChange={(e) => setSelectedScoreSystem(e.target.value)}>
              <option disabled value="_DEFAULT_">
                {loadingSS ? "Loading..." : "Select score system"}
              </option>
              {scoreSystems.map(({ _id, name }) => (
                <option key={_id} value={_id}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {!loadingSS && selectedScoreSystem && (
            <>
              <Form.Group>
                <Form.Label>Best Of</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue={bestOf || "_DEFAULT_"}
                  onChange={(e) => {
                    setBestOf(parseFloat(e.target.value));
                    setShowVeto(
                      selectedScoreSystem.toString() === "5f6c76bc09fc214fea5dc921" &&
                        league?.game?.shortName === "CSGO" &&
                        [1, 3, 5].includes(parseFloat(e.target.value))
                        ? true
                        : false
                    );
                  }}
                >
                  <option disabled value="_DEFAULT_">
                    Select best of
                  </option>
                  {getScoreSystemData()?.configuration?.map(({ bestOf }) => (
                    <option key={bestOf} value={bestOf}>
                      Best Of {bestOf}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Check
                  label="Switch Sides Every Other Map"
                  id="switch-sides"
                  checked={switchSides}
                  onChange={(e) => setSwitchSides(e.target.checked)}
                />
              </Form.Group>
              <Row>
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                    <Form.Check
                      type="checkbox"
                      name="sideselection"
                      checked={sideSelection}
                      label="Side Selection"
                      onChange={(e) => {
                        setSideSelection((prevState) => !prevState);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                    <Form.Check
                      type="checkbox"
                      name="broadcasted"
                      checked={broadcasted}
                      label="Broadcasted"
                      onChange={(e) => {
                        setBroadcasted((prevState) => !prevState);
                      }}
                    />
                  </Form.Group>
                </Col>
                {showVeto && (
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                      <Form.Check
                        type="checkbox"
                        name="vetoFlagEnable"
                        checked={vetoFlag}
                        label="Enable Veto"
                        onChange={(e) => {
                          setVetoFlag(!vetoFlag);
                        }}
                        disabled={match && match.status == "complete"}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {match && (
                <p className="text-danger">
                  NOTE: If you are changing the switch sides setting after match data has already been received, you MUST reset the match
                  first, come back here and change the switch sides setting, then manually fetch data again.
                </p>
              )}
            </>
          )}

          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Round</Form.Label>
                <Form.Control type="number" min={1} value={round} onChange={(e) => setRound(parseFloat(e.target.value))} />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Week</Form.Label>
                <Form.Control type="number" min={1} value={week} onChange={(e) => setWeek(parseFloat(e.target.value))} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <div>
                  <Datetime
                    value={date}
                    onChange={(date) => {
                      setDate(date);
                      setIsDateChanged(true);
                    }}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm:ss"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Time zone</Form.Label>
                <Form.Control
                  as="select"
                  name="timezone"
                  defaultValue={localPrefferedTimezone}
                  value={localPrefferedTimezone}
                  onChange={(e) => {
                    setLocalPrefferedTimezone(e.target.value);
                    setIsDateChanged(true);
                  }}
                >
                  {TIMEZONES.map(({ value, name }, index) => (
                    <option value={value} key={index}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {/* Code commented temporarily and will be added in future deployments */}
          {!match.isLockReschedule && match.status !== "complete" && (
            <>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      name="customRescduling"
                      checked={customReschdule}
                      label="Use custom match rescheduling"
                      onChange={(e) => {
                        setCustomReschdule(!customReschdule);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Earliest Date</Form.Label>
                    <Datetime
                      value={earliestDate ? moment(earliestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                      onChange={setEarliestDate}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm:ss"
                      inputProps={{ disabled: !customReschdule }}
                      isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Latest Date</Form.Label>
                    <Datetime
                      value={latestDate ? moment(latestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                      onChange={setLatestDate}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm:ss"
                      inputProps={{ disabled: !customReschdule }}
                      isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <div className="d-flex">
            <Button style={{ width: "100%" }} type="submit" variant="success" size="lg" disabled={!formValid}>
              {match ? "Edit" : "Create"} Match
            </Button>

            {/* Only if editing */}
            {match && (
              <Button style={{ marginLeft: 15, width: 50 }} onClick={onDeleteMatch} variant="danger" size="lg" block>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateEditMatchModal;
