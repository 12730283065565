import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { toast } from 'react-toastify';

const Accessibility = ({ game, setGame }) => {
  const [accessibilityData, setAccessibilityData] = useState(game.accessibility)
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setValue, watch } = useForm();

  const onSubmit = async (gameData) => {
     setLoading(true);
     let formData = {}
     Object.keys(gameData).map(key => {
       formData[key] = gameData[key] == "true"
     })

    if (game) {
      // Edit
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/games/${game._id}`, {accessibility: formData, field_to_edit: 'accessibility'});
      if (!resData.error) {
        toast.success("Game Updated Successfully");
        if(resData.updatedGame) {
          setGame(resData.updatedGame)
        }
        setLoading(false);
      } else {
        toast.error("Game Updating Failed");
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(accessibilityData).map(key => {
          return (
            <Row>
              <Col xl="6">
                <Form.Group>
                  <Col xl="6">
                    <Form.Label>{key == 'Player Stats' ? 'Show Stats Tabs for Players' : key}</Form.Label>
                  </Col>
                  <Col xl="6">
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      <Form.Check
                        ref={register({ required: "Required" })}
                        type="radio"
                        id={key}
                        name={key}
                        label="Yes"
                        isInvalid={errors.key}
                        value={true}
                        defaultChecked={accessibilityData[key]}
                      />
                      <Form.Check
                        ref={register({ required: "Required" })} s
                        type="radio"
                        id={`${key}0`}
                        name={`${key}`}
                        label="No"
                        isInvalid={errors.key}
                        value={false}
                        defaultChecked={!accessibilityData[key]}
                      />
                    </div>
                  </Col>
                  <Form.Control.Feedback type="invalid">{errors.key && errors.key.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          );

        })}
        <Row>
          <Col xl="3">
            <Button type="submit" variant="success" className="float-left" disabled={loading}>
              {loading ? "Processing..." : game ? "Save" : "Create"}
            </Button>
          </Col>
        </Row>
      </Form>
            
    </>
  );
};

export default Accessibility;
