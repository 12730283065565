import React from "react";
import { Card, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";

const SingleTournament = ({ tournament, onEditLeague, deletingLeague, onRemoveLeague, creationPermission }) => {
  const { _id, name, thumbnailImage } = tournament;
  let tournamentTime = tournament.showOnDate && tournament.showOnDate.slice(11, 16);

  return (
    <Card>
      <Card.Img variant="top" src={thumbnailImage} height={200} />
      <Card.Body style={{ backgroundColor: tournament.hideTournament ? "#e5e5e5" : "" }}>
        {tournament.hideTournament && <p className="hiddenText">HIDDEN</p>}
        <h5>{name}</h5>
        <div className="displayOptions">
          <Button size="sm" variant="primary" className="space-right" as={Link} to={`/games/tournaments/${_id}/matches`}>
            <FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
          </Button>
          <Button size="sm" variant="outline-success" className="space-right" onClick={() => onEditLeague(tournament)}>
            <FontAwesomeIcon icon={faPencilAlt} /> Edit name
          </Button>
          <Button
            size="sm"
            className="space-right"
            variant="outline-danger"
            onClick={() => onRemoveLeague(_id)}
            disabled={deletingLeague === _id || !creationPermission}
          >
            {deletingLeague === _id ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </>
            )}
          </Button>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{tournament.showOnDate && `${moment(tournament.showOnDate).format("HH:mm")} ${moment.tz(tournament.timezone).format("z")} - ${moment(tournament.showOnDate).format("Do MMM YYYY")}`}</Tooltip>}>
            {tournament.showOnDate ? <p className="showDate">{tournament.showOnDate && `${moment(tournament.showOnDate).format("HH:mm")} ${moment.tz(tournament.timezone).format("z")} - ${moment(tournament.showOnDate).format("Do MMM YYYY")}`}</p> : <></>}
          </OverlayTrigger>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SingleTournament;
