import React from "react";
import { Switch } from "react-router-dom";
import { AuthRoute } from "../../utils/CustomRouteTypes";
import AllGames from "./AllGames/AllGames";
import GameGroups from "./Groups/GameGroups/GameGroups";
import GroupSeasons from "./Seasons/GroupSeasons/GroupSeasons";
import SeasonLeagues from "./Leagues/SeasonLeagues/SeasonLeagues";
import LeaguesRouter from "./Leagues/LeaguesRouter";
import TournamentRouter from './Tournaments/TournamentRouter';
import SeasonTournaments from './Tournaments/SeasonTournaments/SeasonTournaments';
import Scrims from "./Scrims/Scrims";

const GamesRouter = () => {
  return (
    <Switch>
      <AuthRoute path="/games/leagues/:id">
        <LeaguesRouter />
      </AuthRoute>
      <AuthRoute path="/games/tournaments/:id">
        <TournamentRouter />
      </AuthRoute>
      <AuthRoute path="/games/seasons/:id/leagues">
        <SeasonLeagues />
      </AuthRoute>
      <AuthRoute path="/games/seasons/:id/tournaments">
        <SeasonTournaments />
      </AuthRoute>
      <AuthRoute path="/games/groups/:id/seasons">
        <GroupSeasons />
      </AuthRoute>
      <AuthRoute path="/games/:id/groups">
        <GameGroups />
      </AuthRoute>
      <AuthRoute path="/scrim-games/:id/scrims">
        <Scrims />
      </AuthRoute>
      
      <AuthRoute path="/games">
        <AllGames settingFeature={false} />
      </AuthRoute>
      <AuthRoute path="/scrim-games">
        <AllGames settingFeature={false} scrims={true}/>
      </AuthRoute>
    </Switch>
  );
};

export default GamesRouter;
