
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";

const ItemsList = ({ game, setGame, fieldName, displayName }) => {
  const [fieldData, setFieldData] = useState(game[fieldName]);
  const [submitting, setSubmitting] = useState(false);

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const onUpdateItem = (atIndex, value) => {
    setFieldData((fieldData) => {
      return fieldData.map((p, i) => (i === atIndex ? value : p));
    });
  };

  const onAddItem = () => {
    setFieldData([...fieldData, ""]);
  };

  const onDeleteItem = (atIndex) => {
    setFieldData(fieldData.filter((_, i) => i !== atIndex));
  };

  const onSave = async () => {
    setSubmitting(true);
    try {
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/games/${game._id}`, {[fieldName]: fieldData.map(data => data.toUpperCase()), field_to_edit: fieldName});

      // update game
      setGame({ ...game, [fieldName]: fieldData });

      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setSubmitting(false);
  };
  console.log("gameRanks: ", fieldData)
  console.log("game: ", game)
  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <Button variant="success" size="sm" onClick={onAddItem}>
          <FontAwesomeIcon icon={faPlus} /> Add {displayName}
        </Button>
      </div>

      {!fieldData.length ? (
        `No ${displayName}s added. Add one using the button at the top right!`
      ) : (
        <Table striped bordered hover responsive key={fieldData.length}>
          <thead>
            <tr>
              <th>{displayName}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fieldData.map((item, i) => (
              <tr key={i}>
                <td>
                  <Form.Control type="text" defaultValue={item} onChange={(e) => onUpdateItem(i, e.target.value)} />
                </td>
                <td>
                  <Button variant="link" className="text-danger" onClick={() => onDeleteItem(i)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      
      {!arraysEqual(game[fieldName], fieldData) && !(fieldData.filter(data => data == '').length) && (
        <div className="mt-3">
          <Button type="button" variant="success" onClick={onSave} disabled={submitting}>
            {submitting ? "Saving" : "Save"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ItemsList;
