import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Dropdown, Card, Media, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { RiCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";

const AddOrganisationMemberModal = ({ show, handleClose, organisation = {}, members = [], pendingInvites, afterAdd }) => {
  const timeout = useRef();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [query, setQuery] = useState("");
  const [memberResults, setMemberResults] = useState([]);
  const [memberResultsLoading, setMemberResultsLoading] = useState(false);
  const [inviting, setInviting] = useState(false);

  useEffect(() => {
    if (!query.length) return setMemberResults([]);
    setMemberResultsLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch/inviteMembers`, {
        query,
        include: "users",
      });

      const searchResults = data
        ?.filter(({ _id }) => !selectedMembers.map(({ _id }) => _id).includes(_id))
        .filter(
          ({ _id }) =>
            !pendingInvites.find(({ user }) => [user && user._id].includes(_id))
        )
        .filter(
          ({ _id }) =>
            !members.find(({ user }) => [user && user._id].includes(_id))
        )
        .map(({ _id, img, name, user }) => ({
          _id,
          img,
          user,
          type: "User",
          title: name,
          subtitle: user ? `${user.firstName} ${user.lastName}` : false,
        }));

      setMemberResults(searchResults);
      setMemberResultsLoading(false);
    }, 500);
  }, [query, organisation, selectedMembers]);

  const addMember = (player) => {
    setMemberResults(false);
    if (!selectedMembers) return false;

    setSelectedMembers([...selectedMembers, { ...player, role: "Owner" }]);
  };

  const onSelectMember = (result) => {
    addMember(result);
    setQuery("");
  };

  const editMember = (playerId, role) => {
    let selectedPlayer = selectedMembers.find((player) => player._id === playerId);
    if (selectedPlayer.type === "User") {
      setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: false } : player)));
    }
  }

  const removeMember = (idToRemove) => {
    setSelectedMembers(selectedMembers.filter(({ _id }) => _id !== idToRemove));
  };

  const inviteMembers = async (e) => {
    e.preventDefault();

    if (!selectedMembers) return false;

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/invites`, {
        members: selectedMembers,
        organisation: organisation._id,
      });
      toast.success("Successfully invited user");
      afterAdd(data.invites);
      setSelectedMembers([]);
      handleClose();
    } catch (e) {
      toast.error("There was a problem inviting selected user");
    }
  };

  if (!organisation._id) return null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invite Members to <strong>{organisation.name}</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={inviteMembers}>
          <Form.Group>
            <Form.Control autoFocus placeholder="Search for members" onChange={(e) => setQuery(e.target.value)} value={query} />
            {(memberResultsLoading || !!memberResults.length) && (
              <Card bg="light">
                <ul className="list-unstyled">
                  {memberResultsLoading ? (
                    <Dropdown.Item className="text-secondary">Loading...</Dropdown.Item>
                  ) : (
                    memberResults.map(({ _id, img, title, subtitle, type, user, player, coach }, i) => (
                      <Dropdown.Item key={_id} onClick={() => onSelectMember({ _id, type, img, title, subtitle, user, player, coach })}>
                        <div className="flex-sb">
                          {img ? (
                            <div className="search-result-img" style={{ backgroundImage: `url(${img})` }} />
                          ) : (
                            <div className="profile-img-with-inital-1-letter">{title.charAt(0)}</div>
                          )}

                          <div className="title-subtitle">
                            <p className="title">{title}</p>
                          </div>
                        </div>
                        <div className="type">{type}</div>
                      </Dropdown.Item>
                    ))
                  )}
                </ul>
              </Card>
            )}
          </Form.Group>

          <div className="dynamic-search-selected-items">
            {selectedMembers.map(({ _id, img, title, subtitle, role, roles, type }, i) => (
              <div key={_id} className="selected-item">
                <div className="item-details">
                  {img ? (
                    <div className="item-img" style={{ backgroundImage: `url(${img})` }} />
                  ) : (
                    <div className="profile-img-with-inital-1-letter">{title.charAt(0)}</div>
                  )}
                  <div className="title-subtitle">
                    <p className="title">{title}</p>
                  </div>
                </div>
                <select name="role" id="role" className="form-input" value={role} onChange={(e) => editMember(_id, e.target.value)}>
                  {["Manager", "Owner"].map((role) => (
                    <option value={role}>{role}</option>
                  ))}
                </select>
                <button onClick={() => removeMember(_id)}>
                  <RiCloseLine size={30} />
                </button>
              </div>
            ))}
          </div>

          <Button type="submit" variant="primary" className="inviteMember" disabled={selectedMembers.length < 1}>
            {inviting ? "Inviting Owners..." : "Invite Owners"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal >
  );
};

export default AddOrganisationMemberModal;
