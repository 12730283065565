import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faListAlt } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import SeasonCreateEditModal from "./includes/SeasonCreateEditModal";
import SingleSeason from "./includes/SingleSeason";
import SeasonsReorderModal from "./includes/SeasonsReorderModal";
import moment from "moment";

const GroupSeasons = () => {
  const { id } = useParams();
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [deletingSeason, setDeletingSeason] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/gameGroups/${id}`).then(({ data }) => {
      console.log("season by group -3 ", data);
      setGroup(data);
      setLoading(false);
    });
  }, [id]);

  const setSeasons = (callback) => {
    setGroup((group) => ({
      ...group,
      seasons: callback(sortSeasons(group.seasons)),
    }));
  };

  const onCreateSeason = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditSeason = (groupData) => {
    setModalData(groupData);
    setShowModal(true);
  };

  const onRemoveSeason = async (id) => {
    setDeletingSeason(id);

    if (window.confirm("Are you sure you want to delete this season? This action is irreversible!")) {
      // Deleting Tournament and Leagues
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/${id}`).then(({ data }) => {
        data.tournaments &&
          data.tournaments.forEach((tournament) => {
            Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}?withMatches=true`).then(({ data }) => {
              data.matches &&
                data.matches.forEach((tournamentMatches) => {
                  Axios.delete(`${process.env.REACT_APP_CORE_API}/api/matches/${tournamentMatches._id}`);
                });
            });
            Axios.delete(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}`);
          });

        data.leagues &&
          data.leagues.forEach((league) => {
            Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}?withMatches=true`).then(({ data }) => {
              data.matches &&
                data.matches.forEach((leagueMatches) => {
                  Axios.delete(`${process.env.REACT_APP_CORE_API}/api/matches/${leagueMatches._id}`);
                });
            });
            Axios.delete(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}`);
          });
      });

      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/seasons/${id}`);
      setSeasons((seasons) => seasons.filter(({ _id }) => _id !== id));
    }

    setDeletingSeason(false);
  };

  const sortSeasons = (seasons) => {
    return seasons.sort(
      (season1, season2) => (season1.startDate && season2.startDate && moment(season1.startDate).diff(moment(season2.startDate))) || 1
    );
  };

  return (
    <div>
      <Breadcrumb>
        {!loading ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {group.game.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/${group.game._id}/groups` }}>
              {group.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Seasons</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="admin-page-title">
        <h2>Seasons</h2>

        <div>
          <Button variant="success" onClick={onCreateSeason}>
            <FontAwesomeIcon icon={faPlus} /> Create
          </Button>
        </div>
      </div>

      {loading ? (
        "Loading"
      ) : !group.seasons.length ? (
        'No seasons to show. Click the "Create" button to create one.'
      ) : (
        <div>
          <Row>
            {sortSeasons(group.seasons).map((season) => (
              <Col xl={4} lg={6} md={6} sm={12} key={season._id}>
                <SingleSeason season={season} onEditSeason={onEditSeason} deletingSeason={deletingSeason} onRemoveSeason={onRemoveSeason} />
              </Col>
            ))}
          </Row>
        </div>
      )}

      <SeasonCreateEditModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} setSeasons={(g) => setSeasons(g)} />
    </div>
  );
};

export default GroupSeasons;
