import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import moment from "moment";
import Axios from "axios";
import Datetime from "react-datetime";
import { serverLocations } from "../../../../../utils/server-locations";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Firday", "Saturday"];

export const Settings = ({ tournament, setTournament }) => {
  const { handleSubmit, register, errors, getValues, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [hideTournament, setHideTournament] = useState(true);
  const [showOn, setShowOn] = useState(false);
  const [location, setLocation] = useState(tournament.csgoServerLocation);
  const [showOnDate, setShowOnDate] = useState(false);
  const [isCSGO, setIsCSGO] = useState(false);
  const [tomorrow, setTomorrow] = useState(moment().subtract(1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [rescheduleType, setRescheduleType] = useState(false);
  const [earliestDate, setEarliestDate] = useState(null);

  // Use Effect
  useEffect(() => {
    setHideTournament(tournament.hideTournament);
    setShowOnDate(tournament.showOnDate ? moment(tournament.showOnDate) : null);
    setShowOn(tournament.showOnDate ? true : false);
    setRescheduleType(tournament?.reschedule?.rescheduleType || "NO_RESCHEDULE");
    setIsCSGO(tournament.game?.name == "CS:GO");
  }, [tournament]);

  // On match reschudule type selection
  const onMatchReschedule = (e) => {
    const { value } = e.target;
    setRescheduleType(value);
  };

  // On submit chagnes
  const onSubmit = async (data) => {
    if (data?.setPlayersDeadlineHr < 0 && data?.setPlayersDeadlineMin < 0) {
      toast.error("Invalid Set Player Deadline Time");
      return;
    }
    const { setPlayersDeadlineHr, setPlayersDeadlineMin, forfeitTeams, ...restData } = data;
    if (showOn && hideTournament) {
      if (moment(showOnDate).format("DD/MM/YYYY HH:mm:ss").valueOf() <= moment().format("DD/MM/YYYY HH:mm:ss").valueOf() || !showOnDate) {
        toast.error("Invalid Time");
        return;
      }
    }

    setLoading(true);

    try {
      const formData = {
        ...restData,
        hideTournament,
        showOnDate: showOn && hideTournament ? moment(showOnDate).tz(tournament.timezone) : null,
        setPlayersDeadline: +setPlayersDeadlineHr * 60 + +setPlayersDeadlineMin,
        reschedule: { rescheduleType },
        forfeitTeam: data.forfeitTeams,
      };
      if (isCSGO) {
        formData.csgoServerLocation = location;
      }
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/setting/${tournament._id}`, formData);
      setTournament((tournament) => ({ ...tournament, ...formData }));

      toast.success("Saved");
    } catch (error) {
      toast.error("There was a problem saving");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row style={{ marginTop: "0.5em" }}>
          <Col md={3}>
            <Form.Group>
              <Form.Check
                type="checkbox"
                name="hideLeague"
                checked={hideTournament}
                label="Hide Tournament"
                onChange={() => {
                  setHideTournament(!hideTournament);
                }}
              />
            </Form.Group>
          </Col>

          {hideTournament && (
            <Col md={2}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  name="showOn"
                  checked={showOn}
                  label="Show On"
                  onChange={() => {
                    setShowOn(!showOn);
                  }}
                />
              </Form.Group>
            </Col>
          )}

          {hideTournament && showOn && (
            <Row>
              <Form.Label style={{ marginRight: "1em" }}>Date</Form.Label>
              <div style={{ marginTop: "-0.4em" }}>
                <Datetime
                  defaultValue={showOnDate ? showOnDate : ""}
                  onChange={setShowOnDate}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm:ss"
                  isValidDate={(currentDate) => currentDate.isAfter(tomorrow)}
                />
              </div>
            </Row>
          )}
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Server Location</Form.Label>
              <Form.Control
                as="select"
                value={location}
                id="csgoServerLocation"
                name="csgoServerLocation"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                onBlur={handleSubmit(onSubmit)}
              >
                <option value={false} disabled>
                  Select Location
                </option>
                {serverLocations.map((serverLocation, index) => (
                  <option key={index} value={serverLocation.key}>
                    {serverLocation.value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Set Players Deadline (Hrs)</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="setPlayersDeadlineHr"
                name="setPlayersDeadlineHr"
                defaultValue={parseInt(tournament.setPlayersDeadline / 60)}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Set Players Deadline (Min)</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="setPlayersDeadlineMin"
                name="setPlayersDeadlineMin"
                defaultValue={tournament.setPlayersDeadline % 60}
                onBlur={handleSubmit(onSubmit)}
                max={59}
                min={0}
              />
              <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Match Rescheduling</Form.Label>
              <Form.Control as="select" value={rescheduleType} id="matchReschedule" name="matchReschedule" onChange={onMatchReschedule}>
                <option value="NO_RESCHEDULE">No Reschduling</option>
                <option value="WEEK">Within The Week</option>
                <option value="INTERVAL">Within An Interval</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Tournament Finished?</Form.Label>
              <Form.Control
                as="select"
                ref={register({ required: true })}
                defaultValue={tournament.tournamentFinished || false}
                id="tournamentFinished"
                name="tournamentFinished"
                onBlur={() => {
                  if (
                    tournament.matches.length !== 0 &&
                    tournament.matches.some((match) => moment(match.datetime) >= moment()) &&
                    getValues("tournamentFinished") === "true"
                  ) {
                    if (
                      window.confirm("There are some upcoming matches in the tournament. Are you sure to make this tournament finished?")
                    ) {
                      handleSubmit(onSubmit)();
                    } else {
                      setValue("tournamentFinished", false);
                    }
                  } else {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Form.Control>
              {tournament.matches.length !== 0 &&
              tournament.matches.some((match) => moment(match.datetime) >= moment()) &&
              tournament.tournamentFinished === "true" ? (
                <span className="text-danger">There are some upcoming matches in the tournament</span>
              ) : null}
              <Form.Control.Feedback type="invalid">{errors.tournamentFinished && errors.tournamentFinished.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Forfeit Teams Failing To Set Player</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control as="select" ref={register()} id="forfeitTeams" name="forfeitTeams" defaultValue={tournament.forfeitTeam}>
                  <option value="doNothing">Do Nothing</option>
                  <option value="useLastPlayers">Use Last Players</option>
                  <option value="forfietTeam">Forfeit Team</option>
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default Settings;
