import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const ViewRole = ({ roleName, userRole }) => {
  return (
    <div>
      <Row>
        <Col md={12}>
          <Form.Group>
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              type="text"
              value={roleName}
              disabled={true}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Check
        type="switch"
        label="Calendar"
        className="roleSwitches"
        disabled={true}
        checked={userRole?.calendar}
      />
      <Form.Check
        type="switch"
        label="Analyzer"
        className="roleSwitches"
        disabled={true}
        checked={userRole?.analyzer}
      />
      <Form.Check
        type="switch"
        label="SMS"
        className="roleSwitches"
        disabled={true}
        checked={userRole?.sms}
      />
      <Form.Check
        type="switch"
        label="Admin"
        className="roleSwitches"
        disabled={true}
        checked={userRole?.admin}
      />
      <Form.Check
        type="switch"
        label="Front Page"
        className="roleSwitchesParent"
        disabled={true}
        checked={userRole?.frontPage}
      />
      <Form.Check
        type="switch"
        label="Entity Data"
        className="roleSwitchesParent"
        disabled={true}
        checked={userRole?.entityData}
      />
      <Form.Check
        type="switch"
        label="Permissions"
        className="roleSwitchesChild"
        disabled={true}
        checked={userRole?.permissions}
      />
      <Form.Check
        type="switch"
        label="Roles"
        className="roleSwitchesChild"
        disabled={true}
        checked={userRole?.roles}
      />
      <Form.Check
        type="switch"
        label="Games"
        className="roleSwitchesParent"
        disabled={true}
        checked={userRole?.game}
      />
      <Form.Check
        type="switch"
        label="Advertisments"
        className="roleSwitchesParent"
        disabled={true}
        checked={userRole?.advertisements}
      />
      <Form.Check
        type="switch"
        label="Leagues and Tournaments"
        className="roleSwitchesParent"
        disabled={true}
        checked={userRole?.leaguesAndTournaments}
      />
      <Form.Check
        type="switch"
        label="Matches"
        className="roleSwitchesChild"
        disabled={true}
        checked={userRole?.matches}
      />
      <Form.Check
        type="switch"
        label="Settings"
        className="roleSwitchesChild"
        disabled={true}
        checked={userRole?.settings}
      />
      <Form.Check
        type="switch"
        label="Creation"
        className="roleSwitchesChild"
        disabled={true}
        checked={userRole?.creation}
      />
      <Form.Check
        type="switch"
        label="Score System"
        className="roleSwitchesParent"
        disabled={true}
        checked={userRole?.scoreSystem}
      />
    </div>
  );
};

export default ViewRole;
