import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import SingleMember from "../include/SingleMember";
import AddTeamMemberModal from "../include/AddTeamMemberModal"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faEye,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import { toast } from "react-toastify";

const Members = ({ team, setTeam }) => {
  const [members, setMembers] = useState(team.members || []);
  const [existedmembers, setExistedMembers] = useState([]);
  const [orgmembers, setOrgMembers] = useState([]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [pendingInvites, setPendingInvites] = useState(team.pendingInvites || []);

  useEffect(() => {
    if (team.members) {
      setExistedMembers([...existedmembers, ...team.members]);
    }
    if (team.organisation && team.organisation.owners) {
      setExistedMembers([...existedmembers, ...team.organisation.owners]);
      setOrgMembers([...team.organisation.owners]);
    }
  }, [team]);

  const getPlayerPositionData = (role = "") => {
    const roleSplit = role.split(":")[1];
    if (roleSplit) return (team.game || {}).configuration.playerTypes.find(({ name }) => name === roleSplit);
    return false;
  };

  const onDeleteMember = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/members/${id}`);
        setMembers((members) => members.filter(({ _id }) => _id !== id));
        setTeam((team) => ({ ...team, members: members.filter(({ _id }) => _id !== id) }));

        toast.success("Successfully deleted member");
      } catch (e) {
        toast.error("Could not delete member");
      }
    }
  };

  const afterInviteMembers = (invites) => {
    setPendingInvites(invites.map((invite) => ({ ...invite, type: "invite" })));
    setTeam((team) => ({ ...team, pendingInvites: invites }));
  };

  const onDeleteInvite = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/invites/${id}`);
        toast.success("Successfully deleted invite");
        setPendingInvites((invites) => invites.filter(({ _id }) => _id !== id));
        setTeam((team) => ({ ...team, pendingInvites: () => pendingInvites.filter(({ _id }) => _id !== id) }));
      } catch (e) {
        toast.error("Could not delete invite");
      }
    }
  };

  const playerTypes = (team.game || {}).configuration.playerTypes.map(({ name }) => `Player:${name}`);
  const memberSections = [
    { name: "Pending Invites", members: pendingInvites && pendingInvites.map((invite) => ({ ...invite, type: "invite" })) },
    {
      name: "Players",
      members: members.filter(({ role }) => role && playerTypes.includes(role) && !role.toLowerCase().includes("sub")),
    },
    { name: "Coaches", members: members.filter(({ role }) => role === "Coach") },
    { name: "Others", members: members.filter(({ role }) => role === "") },
    {
      name: "Staff",
      members:
        [
          ...members.filter(({ role }) => ["Manager", "Owner"].includes(role)),
          ...orgmembers.map((owner) => ({ ...owner, role: `${owner?.role || "Owner"} (Organisation)` })),
        ],
    },
  ];

  let ordering = {},
    sortOrder = ["Player:TOP", "Player:JUNGLE", "Player:MID", "Player:BOT", "Player:SUPPORT"];
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

  return (
    <div>
      <Button className="addMember" onClick={() => setShowAddMember(true)}>
        Add player, coach or staff
      </Button>

      {memberSections.map(
        ({ name, members = [] }) =>
          !!members.length && (
            <>
              <div className="cardHeader">{name}</div>
              <Col md={{ span: 10, offset: 1 }}>
                {members
                  .sort((a, b) => ordering[a.role] - ordering[b.role])
                  .map((singleMember) => {
                    if (singleMember && singleMember._id) {
                      const { _id, user, player, coach, role, type } = singleMember;
                      return (
                        <SingleMember
                          key={_id}
                          img={user ? user.avatarImage : player ? player.avatarImage : false}
                          title={
                            user
                              ? `${user.firstName}${user.nickname ? ` "${user.nickname}" ` : " "}${user.lastName}`
                              : player
                                ? player.name
                                : coach
                                  ? coach.name
                                  : false
                          }
                          subtitle={
                            player
                              ? `${player.user.firstName}${player.user.nickname ? ` "${player.user.nickname}" ` : " "}${player.user.lastName
                              }`
                              : coach
                                ? `${coach.user.firstName}${coach.user.nickname ? ` "${coach.user.nickname}" ` : " "}${coach.user.lastName
                                }`
                                : false
                          }
                          pending={type === "invite" ? true : false}
                          position={player ? getPlayerPositionData(role) : false}
                          role={!player ? role : false}
                          nationality={
                            player ? player.user.nationality : coach ? coach.user.nationality : user ? user.nationality : false
                          }
                          actions={
                            type !== "invite" ?
                              (
                                [
                                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View</Tooltip>}>
                                    <a
                                      className="btn text-secondary"
                                      href={player
                                        ? `${process.env.REACT_APP_LEAGUES_URL}/game-profile/${player._id}`
                                        : coach
                                          ? `${process.env.REACT_APP_LEAGUES_URL}/coaches/${coach._id}`
                                          : user
                                            ? `${process.env.REACT_APP_LEAGUES_URL}/players/${user._id}`
                                            : false}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ padding: "8px" }}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </a>
                                  </OverlayTrigger>,
                                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
                                    <Button variant="link" className="btn text-danger" onClick={() => onDeleteMember(_id)}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </OverlayTrigger>
                                ]
                              ) : (
                                [
                                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
                                    <Button variant="link" className="btn text-danger" onClick={() => onDeleteInvite(_id)}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </OverlayTrigger>
                                ]
                              )
                          }
                        />
                      );
                    }
                  })}
              </Col>
            </>
          )
      )}

      <AddTeamMemberModal
        show={showAddMember}
        handleClose={() => setShowAddMember(false)}
        team={team}
        members={existedmembers}
        pendingInvites={pendingInvites}
        afterAdd={afterInviteMembers}
      />
    </div>
  );
};

export default Members;
