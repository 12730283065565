import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import EditImageModal from "../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const GameCreateEditModal = ({ show, setShow, data = {}, setGames }) => {
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({
    whiteIcon: false,
    blackIcon: false,
  });

  const whiteIcon = watch("whiteIcon", data.whiteIcon);
  const blackIcon = watch("blackIcon", data.blackIcon);

  const toggleModal = (modalName, state) => {
    setShow(!state);
    setModals({ ...modals, [modalName]: state });
  };

  const onUpdateImage = (icon, url) => {
    setValue(icon, url);
    toggleModal(icon, false);
  };

  const onSubmit = async (gameData) => {
    setLoading(true);
    let formData = {
      ...gameData,
      gameRanks: gameData.gameRanks
        .split("\n")
        .filter(Boolean)
        .map((rank) => rank.toUpperCase()),
    };

    if (data) {
      // Edit
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/games/${data._id}`, formData);

      // update game in parent state
      setGames((games) => games.map((game) => (game._id === data._id ? resData.updatedGame : game)));
    } else {
      // Create
      const { data: resData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/games`, formData);

      // Update parent state
      setGames((games) => [...games, resData.game]);
    }

    setLoading(false);
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{data ? "Edit" : "Create"} Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="text"
                id="name"
                name="name"
                defaultValue={data.name}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Short name</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="text"
                id="shortName"
                name="shortName"
                defaultValue={data.shortName}
                isInvalid={errors.shortName}
                placeholder="For example: LOL"
              />
              <Form.Control.Feedback type="invalid">{errors.shortName && errors.shortName.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Game URL</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="text"
                id="gameUrl"
                name="gameUrl"
                defaultValue={data.gameUrl}
                isInvalid={errors.gameUrl}
                placeholder="For example: https://nlc.leagues.gg/game/game-name"
              />
              <Form.Control.Feedback type="invalid">{errors.gameUrl && errors.gameUrl.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Acceptable Ranks in Hierarchical Order (Highest Rank First)</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                as="textarea"
                rows={3}
                id="gameRanks"
                name="gameRanks"
                isInvalid={errors.gameRanks}
                defaultValue={data.gameRanks}
                placeholder="Enter game formats in hierarchical order (Highest First)"
              />
              <Form.Control.Feedback type="invalid">{errors.gameRanks && errors.gameRanks.message}</Form.Control.Feedback>
            </Form.Group>

            {/* Icons */}
            <div className="d-flex">
              <Form.Group className="mr-4">
                <Form.Label>White Icon</Form.Label>
                <div
                  onLoad={register({ name: "whiteIcon" })}
                  className="edit-image-btn"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${whiteIcon})`,
                    width: 100,
                    height: 100,
                  }}
                  onClick={() => toggleModal("whiteIcon", true)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </div>
              </Form.Group>

              <Form.Group className="mr-4">
                <Form.Label>Black Icon</Form.Label>
                <div
                  onLoad={register({ name: "blackIcon" })}
                  className="edit-image-btn"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${blackIcon})`,
                    width: 100,
                    height: 100,
                  }}
                  onClick={() => toggleModal("blackIcon", true)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </div>
              </Form.Group>
            </div>

            <Button type="submit" variant="success" className="float-right" disabled={loading}>
              {loading ? "Processing..." : data ? "Edit" : "Create"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <EditImageModal
        show={modals.whiteIcon}
        onHide={() => toggleModal("whiteIcon", false)}
        name="White Game Icon"
        width={200}
        height={200}
        afterImageUpload={(file) => onUpdateImage("whiteIcon", file)}
      />
      <EditImageModal
        show={modals.blackIcon}
        onHide={() => toggleModal("blackIcon", false)}
        name="Black Game Icon"
        width={200}
        height={200}
        afterImageUpload={(file) => onUpdateImage("blackIcon", file)}
      />
    </>
  );
};

export default GameCreateEditModal;
