import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { TIMEZONES } from "../../../../../utils/timezones";

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const GenerateMatches = () => {
  const { id } = useParams();
  const history = useHistory();
  const [league, setLeague] = useState(false);

  const [loadingSS, setLoadingSS] = useState(true);
  const [scoreSystems, setScoreSystems] = useState([]);
  const [vetoFlag, setVetoFlag] = useState(true);
  const [showVeto, setShowVeto] = useState(false);

  const [selectedScoreSystem, setSelectedScoreSystem] = useState(false);
  const [bestOf, setBestOf] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [weeklySchedule, setWeeklySchedule] = useState([]);
  const [noOfWeeks, setNoOfWeeks] = useState(0);
  const [teamMeetQuantity, setTeamMeetQuantity] = useState(0);
  const [broadcasted, setBroadcasted] = useState(false);
  const [sideSelection, setSideSelection] = useState(false);
  const [prefferedTimezone, setPrefferedTimezone] = useState(TIMEZONES[0].value);

  const numberOfTeamsWithByes = league ? (league.teams.length % 2 === 1 ? league.teams.length + 1 : league.teams.length) : 0;
  const numberOfMatches = (numberOfTeamsWithByes / 2) * (numberOfTeamsWithByes - 1) * teamMeetQuantity;
  const roundsPerWeek = Math.max(Math.ceil(((numberOfTeamsWithByes - 1) * teamMeetQuantity) / noOfWeeks), 0);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scoreSystems`).then(({ data }) => {
      setScoreSystems(data);
      setShowVeto(false);
      setLoadingSS(false);
    });

    Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}`).then(({ data }) => {
      setLeague(data);
    });
  }, [id]);

  const getScoreSystemData = () => {
    if (selectedScoreSystem) {
      return scoreSystems.find(({ _id }) => _id === selectedScoreSystem);
    }

    return false;
  };

  const updateWeeklySchedule = (index, key, value) => {
    const schedule = weeklySchedule;
    schedule[index] = { ...schedule[index], [key]: value };
    setWeeklySchedule([...schedule]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let vetoEnable = showVeto ? vetoFlag : false;
    const updatedWeeklySchedule = weeklySchedule.reduce(
      (acc, _scheduleItem) => [...acc, { ..._scheduleItem, time: moment(_scheduleItem.time).tz(prefferedTimezone, true) }],
      []
    );

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}/matches/generate?isVeto=${vetoEnable}`, {
        league: id,
        teams: league.teams.map(({ team }) => team),
        scoreSystem: selectedScoreSystem,
        bestOf,
        startDate,
        weekSchedule: updatedWeeklySchedule,
        durationWeeks: noOfWeeks,
        teamsMeetQty: teamMeetQuantity,
        broadcasted,
        sideSelection,
      });
      toast.success("Successfully generated matches");
      history.push("matches");
    } catch (e) {
      toast.error("Problem generating matches!");
    }
  };

  return (
    <div>
      <div className="mb-4">
        <Button as={Link} to="matches" variant="link" className="mr-2 mb-2 p-0">
          <FontAwesomeIcon icon={faArrowLeft} /> Back to league
        </Button>
        <h2>Generate Matches</h2>
      </div>

      <h5 className="mt-3 mb-3">General</h5>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Score System</Form.Label>
          <Form.Control as="select" value={selectedScoreSystem || "_DEFAULT_"} onChange={(e) => setSelectedScoreSystem(e.target.value)}>
            <option disabled value="_DEFAULT_">
              {loadingSS ? "Loading..." : "Select score system"}
            </option>
            {scoreSystems.map(({ _id, name }) => (
              <option key={_id} value={_id}>
                {name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {selectedScoreSystem && (
          <Form.Group>
            <Form.Label>Best Of</Form.Label>
            <Form.Control
              as="select"
              value={bestOf || "_DEFAULT_"}
              onChange={(e) => {
                setBestOf(parseFloat(e.target.value));
                setShowVeto(
                  selectedScoreSystem.toString() === "5f6c76bc09fc214fea5dc921" &&
                    league?.game?.shortName === "CSGO" &&
                    [1, 3, 5].includes(parseFloat(e.target.value))
                    ? true
                    : false
                );
              }}
            >
              <option disabled value="_DEFAULT_">
                Select best of
              </option>
              {getScoreSystemData().configuration.map(({ bestOf }) => (
                <option key={bestOf} value={bestOf}>
                  Best Of {bestOf}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {league && bestOf && (
          <div className="d-flex">
            <Form.Group className="mr-4">
              <Form.Label>Start Date</Form.Label>
              <div>
                <Form.Control as={DatePicker} selected={startDate} onChange={setStartDate} inline={true} />
              </div>
            </Form.Group>

            <div>
              <Form.Group>
                <Form.Label>Number of Weeks</Form.Label>
                <Form.Control
                  type="number"
                  value={noOfWeeks}
                  onChange={(e) => e.target.value >= 0 && setNoOfWeeks(e.target.value)}
                  min={1}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>How many times should teams play against each other?</Form.Label>
                <Form.Control
                  type="number"
                  value={teamMeetQuantity}
                  onChange={(e) => e.target.value >= 0 && setTeamMeetQuantity(e.target.value)}
                  min={1}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Time zone</Form.Label>
                <Form.Control
                  as="select"
                  value={prefferedTimezone}
                  defaultValue={prefferedTimezone}
                  onChange={(e) => setPrefferedTimezone(e.target.value)}
                >
                  {TIMEZONES.map(({ name, value }, index) => (
                    <option value={value} key={index}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Teams</Form.Label>
                    <Form.Control plaintext readOnly value={league.teams.length} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Rounds</Form.Label>

                    <Form.Control plaintext readOnly value={(numberOfTeamsWithByes - 1) * teamMeetQuantity} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Matches</Form.Label>
                    <Form.Control plaintext readOnly value={numberOfMatches} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                    <Form.Check
                      type="checkbox"
                      name="broadcasted"
                      checked={broadcasted}
                      label="Broadcasted"
                      onChange={(e) => {
                        setBroadcasted((prevState) => !prevState);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                    <Form.Check
                      type="checkbox"
                      name="sideSelection"
                      checked={sideSelection}
                      label="Side Selection"
                      onChange={(e) => {
                        setSideSelection((prevState) => !prevState);
                      }}
                    />
                  </Form.Group>
                </Col>
                {showVeto && (
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                      <Form.Check
                        type="checkbox"
                        name="vetoFlagEnable"
                        checked={vetoFlag}
                        label="Enable Veto"
                        onChange={(e) => {
                          setVetoFlag(!vetoFlag);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        )}

        {!!(noOfWeeks && teamMeetQuantity) && (
          <>
            <h5 className="mt-4 mb-3">Weekly Schedule</h5>

            {Array(roundsPerWeek)
              .fill()
              .map((_, i) => (
                <div className="d-inline-block mr-4">
                  <Form.Group>
                    <Form.Label>Round {i + 1} Day</Form.Label>
                    <Form.Control
                      as="select"
                      value={weeklySchedule[i] ? weeklySchedule[i].day : "_DEFAULT_"}
                      onChange={(e) => updateWeeklySchedule(i, "day", e.target.value)}
                    >
                      <option disabled value="_DEFAULT_">
                        Select day
                      </option>
                      {weekdays.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Round {i + 1} Time</Form.Label>
                    <div>
                      <Form.Control
                        as={DatePicker}
                        value={weeklySchedule[i] && moment(weeklySchedule[i].time).format("HH:mm")}
                        onChange={(e) => updateWeeklySchedule(i, "time", moment(e).toISOString())}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                  </Form.Group>
                </div>
              ))}

            <Button
              type="submit"
              variant="success"
              size="lg"
              className="mt-4 mb-5"
              block
              disabled={weeklySchedule.filter((s) => s).length !== roundsPerWeek}
            >
              Generate Matches
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default GenerateMatches;
