import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import GroupCreateEditModal from "./includes/GroupCreateEditModal";
import SingleGroup from "./includes/SingleGroup";

const GameGroups = () => {
  const { id } = useParams();
  const [game, setGame] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  console.log("game groups -- ", game?.groups);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${id}`).then(({ data }) => {
      setGame(data);
      setLoading(false);
    });
  }, [id]);

  const setGroups = (callback) => {
    setGame((game) => ({
      ...game,
      groups: callback(game.groups),
    }));
  };

  const onCreateGroup = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditGroup = (groupData) => {
    setModalData(groupData);
    setShowModal(true);
  };

  const onRemoveGroup = async (id) => {
    setDeletingGroup(id);

    if (window.confirm("Are you sure you want to delete this group? This action is irreversible!")) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/gameGroups/${id}`).then(({ data }) => {
        data.seasons &&
          data.seasons.forEach((season) => {
            Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/${season._id}`).then(({ data }) => {
              data.tournaments &&
                data.tournaments.forEach((tournament) => {
                  Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}?withMatches=true`).then(({ data }) => {
                    data.matches &&
                      data.matches.forEach((tournamentMatches) => {
                        Axios.delete(`${process.env.REACT_APP_CORE_API}/api/matches/${tournamentMatches._id}`);
                      });
                  });
                  Axios.delete(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}`);
                });

              data.leagues &&
                data.leagues.forEach((league) => {
                  Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}?withMatches=true`).then(({ data }) => {
                    data.matches &&
                      data.matches.forEach((leagueMatches) => {
                        Axios.delete(`${process.env.REACT_APP_CORE_API}/api/matches/${leagueMatches._id}`);
                      });
                  });
                  Axios.delete(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}`);
                });
            });
            Axios.delete(`${process.env.REACT_APP_CORE_API}/api/seasons/${season._id}`);
          });
      });

      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/gameGroups/${id}`);
      setGroups((groups) => groups.filter(({ _id }) => _id !== id));
    }

    setDeletingGroup(false);
  };

  return (
    <div>
      <Breadcrumb>
        {!loading ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {game.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Groups</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="admin-page-title">
        <h2>Groups</h2>
        <Button variant="success" onClick={onCreateGroup}>
          <FontAwesomeIcon icon={faPlus} /> Create
        </Button>
      </div>

      {loading ? (
        "Loading"
      ) : (
        <Row>
          {game.groups.map((group) => (
            <Col xl={4} lg={6} md={6} sm={12} key={group._id}>
              <SingleGroup
                group={group}
                color={game.mainColour}
                onEditGroup={onEditGroup}
                deletingGroup={deletingGroup}
                onRemoveGroup={onRemoveGroup}
              />
            </Col>
          ))}
        </Row>
      )}

      <GroupCreateEditModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} setGroups={(g) => setGroups(g)} />
    </div>
  );
};

export default GameGroups;
