import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, ToggleButton } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import EditImageModal from "../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import RichTextbox from "./includes/RichTextbox";
import Datetime from "react-datetime";
import { keysIn } from "lodash";
import { countries } from "../../../../../utils/countries";
import { TIMEZONES } from "../../../Leagues/LeagueMatches/Tabs/timezones";
import moment from "moment";

// const TIMEZONES = ["BST", "CEST", "CET", "EEST", "EET", "FET", "GET", "GMT", "IST", "KUYT", "MSD", "MSK", "SAMT", "TRT", "WEST", "WET"];
const FORMAT = ["Single Elimination", "Double Elimination", "King of the Hill"];

const Information = ({ tournament, setTournament }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [country, setCountry] = useState(tournament.country.code);
  const [modals, setModals] = useState({});
  const [poweredBy, setPoweredBy] = useState("");
  const [about, setAbout] = useState(tournament.about);
  const [startDate, setStartDate] = useState(moment().set({ hours: 18, minutes: 0, seconds: 0 }));
  const [registerationDeadline, setRegisterationDeadline] = useState(moment().set({ hours: 18, minutes: 0, seconds: 0 }));
  const [nationalities, setNationalities] = useState([]);
  const [showVeto, setShowVeto] = useState(false);

  const [selectedTimezone, setSelectedTimezone] = useState();
  const [selectedFormat, setSelectedFormat] = useState();
  const [replaceAd, setReplaceAd] = useState({ firstAd: tournament?.showFirstAd, secondAd: tournament?.showSecondAd });
  const [abbreviation, setAbbreviation] = useState("");

  const pickModeOptions = [
    { name: "Tournament draft", value: "TOURNAMENT_DRAFT" },
    { name: "Draft pick", value: "DRAFT_PICK" },
    { name: "Blind pick", value: "BLIND_PICK" },
    { name: "All Random", value: "ALL_RANDOM" },
  ];

  useEffect(() => {
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
    setStartDate(new Date(tournament.startDate));
    setRegisterationDeadline(new Date(tournament.registerationDeadline));
  }, []);

  useEffect(() => {
    setShowVeto(tournament.game?.name == "CS:GO");
  }, [tournament]);

  const onUpdateImage = async (type, imageUrl) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}/image`, {
        type,
        image: imageUrl,
      });
      setTournament((tournament) => ({ ...tournament, ...data.updatedImages }));
      setModals({ ...modals, [type]: false });
    } catch (err) {
      toast.error("There was a problem updating tournament images!");
    }
  };
  const replaceAdFunc = (key) => {
    replaceAd[key] = !replaceAd[key];
    console.log(replaceAd);
    setReplaceAd({ ...replaceAd });
  };

  const onSubmit = async (data) => {
    // if (data?.setPlayersDeadlineHr < 0 || data?.setPlayersDeadlineMin < 0) {
    //   toast.error("Invalid Set Player Deadline Time");
    //   return;
    // }
    setLoading(true);
    // data.setPlayersDeadline = parseInt(data.setPlayersDeadlineHr * 60) + parseInt(data.setPlayersDeadlineMin);
    // delete data.setPlayersDeadlineHr;
    // delete data.setPlayersDeadlineMin;
    try {
      // Country
      if (country) {
        const nationality = nationalities.find((data) => data.alpha2Code === country);
        data = { ...data, country: { code: country, name: nationality.demonym } };
      }

      data.isOpen = data?.isOpen === "true" ? true : false;
      data.teamSlots = data?.teamSlots ? parseInt(data.teamSlots) : 0;
      data.poweredBy = poweredBy;
      data.startDate = startDate;
      data.about = about;
      data.registerationDeadline = registerationDeadline;
      data.showFirstAd = replaceAd.firstAd;
      data.showSecondAd = replaceAd.secondAd;
      data.abbreviation = abbreviation;
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}`, data);

      // update tournaments
      setTournament((tournament) => ({ ...tournament, ...data }));
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setLoading(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                ref={register({ required: true })}
                type="text"
                id="name"
                name="name"
                defaultValue={tournament.name}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label>Open for</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                as="select"
                ref={register({ required: true })}
                id="country"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                onBlur={handleSubmit(onSubmit)}
              >
                <option value="">Select country</option>
                {/* {Object.keys(COUNTRIES).map((code, key) => (
                  <option key={key} value={code}>
                    {COUNTRIES[code]}
                  </option>
                ))} */}
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={alpha2Code}>
                    {demonym}
                  </option>
                ))}
              </Form.Control>
              {!!country && (
                <img
                  src={`https://www.flagcdn.com/128x96/${country.toLowerCase()}.png`}
                  width={32}
                  height={32}
                  alt={country}
                  className="ml-2"
                />
              )}
            </div>
            <Form.Control.Feedback type="invalid">{errors.country && errors.country.message}</Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Time zone</Form.Label>
              <Form.Control
                as="select"
                ref={register({ required: true })}
                defaultValue={tournament.timezone}
                id="timezone"
                name="timezone"
                onBlur={(e) => {
                  setSelectedTimezone(e.target.value);
                  handleSubmit(onSubmit);
                }}
              >
                <option value="">Select timezone</option>
                {TIMEZONES.map(({ name, value }) => (
                  <option value={value}>{name}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.timezone && errors.timezone.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Abbreviation</Form.Label>
              <Form.Control
                name="abbreviation"
                id="abbreviation"
                ref={register()}
                defaultValue={tournament.abbreviation || ""}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.abbreviation && errors.abbreviation.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Division</Form.Label>
              <Form.Control
                name="tournamentDivision"
                id="tournamentDivision"
                ref={register()}
                defaultValue={tournament.tournamentDivision || ""}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.tournamentDivision && errors.tournamentDivision.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Division Name</Form.Label>
              <Form.Control
                name="tournamentDivisionName"
                id="tournamentDivisionName"
                ref={register()}
                defaultValue={tournament.tournamentDivisionName || ""}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tournamentDivisionName && errors.tournamentDivisionName.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Datetime
                id="startDate"
                name="startDate"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm:ss"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e);
                }}
                onBlur={handleSubmit(onSubmit)}
              />
              {/* <Form.Control
                ref={register()}
                type="date"
                id="startDate"
                name="startDate"
                defaultValue={new Date(tournament.startDate)}
                onBlur={handleSubmit(onSubmit)}
              /> */}
              <Form.Control.Feedback type="invalid">{errors.startDate && errors.startDate.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Registeration Deadline</Form.Label>
              <Datetime
                id="registerationDeadline"
                name="registerationDeadline"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm:ss"
                value={registerationDeadline}
                onChange={(e) => {
                  setRegisterationDeadline(e);
                }}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.registerationDeadline && errors.registerationDeadline.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Tournament Format</Form.Label>
              <Form.Control
                ref={register()}
                id="tournamentFormate"
                name="tournamentFormate"
                defaultValue={tournament.tournamentFormate}
                onBlur={(e) => {
                  setSelectedFormat(e.target.value);
                  handleSubmit(onSubmit);
                }}
                as="select"
              >
                {FORMAT.map((format) => (
                  <option value={format}>{format}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Open Tournament</Form.Label>
              <Form.Control
                ref={register()}
                id="isOpen"
                name="isOpen"
                defaultValue={tournament.isOpen}
                onBlur={handleSubmit(onSubmit)}
                as="select"
              >
                <option value={true}>True</option>
                <option value={false}>False</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Max Team Slots</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="teamSlots"
                name="teamSlots"
                min={4}
                defaultValue={tournament.teamSlots}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.startDate && errors.startDate.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Team Size</Form.Label>
              <Form.Control
                ref={register()}
                id="teamSize"
                name="teamSize"
                defaultValue={tournament.teamSize}
                onBlur={handleSubmit(onSubmit)}
                as="select"
              >
                <option value={1}>1 vs 1</option>
                <option value={3}>3 vs 3</option>
                <option value={5}>5 vs 5</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Number Of Subs</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="numberOfSubs"
                name="numberOfSubs"
                defaultValue={tournament.numberOfSubs || 5}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.teamSize && errors.teamSize.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Game Server</Form.Label>
              <Form.Control
                ref={register()}
                id="gameServer"
                name="gameServer"
                defaultValue={tournament.gameServer}
                onBlur={handleSubmit(onSubmit)}
                as="select"
              >
                <option value={"NA"}>NA</option>
                <option value={"EUW"}>EUW</option>
                <option value={"ENU"}>ENU</option>
                <option value={"KR"}>KR</option>
                <option value={"BR"}>BR</option>
                <option value={"JP"}>JP</option>
                <option value={"RU"}>RU</option>
                <option value={"OCE"}>OCE</option>
                <option value={"TR"}>TR</option>
                <option value={"LAN"}>LAN</option>
                <option value={"LAS"}>LAS</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Prize Text</Form.Label>
              <Form.Control
                ref={register()}
                type="text"
                id="prizeText"
                name="prizeText"
                defaultValue={tournament.prizeText}
                onBlur={handleSubmit(onSubmit)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Map</Form.Label>
              <Form.Control ref={register()} id="map" name="map" defaultValue={tournament.map} onBlur={handleSubmit(onSubmit)} as="select">
                <option value={"SUMMONERS_RIFT"}>Summoner's Rift</option>
                <option value={"HOWLING_ABYSS"}>Howling Abyss</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Pick Mode</Form.Label>
              <Form.Control
                ref={register()}
                id="pickMode"
                name="pickMode"
                defaultValue={tournament.pickMode || "TOURNAMENT_DRAFT"}
                onBlur={handleSubmit(onSubmit)}
                as="select"
              >
                {pickModeOptions.map(({ name, value }) => (
                  <option id={name} value={value}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Entry Fee</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="entryFee"
                name="entryFee"
                min={0}
                defaultValue={tournament.entryFee}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.startDate && errors.startDate.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Hosted By</Form.Label>
              <Form.Control
                ref={register()}
                type="text"
                id="hostedBy"
                name="hostedBy"
                defaultValue={tournament.hostedBy}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.tournamentGroup && errors.tournamentGroup.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {showVeto && (
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  ref={register()}
                  type="checkbox"
                  id="canPlayersVeto"
                  name="canPlayersVeto"
                  label="Allow Players to Veto"
                  checked={tournament.canPlayersVeto}
                  onChange={handleSubmit(onSubmit)}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Form.Group>
          <Form.Label>Discord Link</Form.Label>
          <Form.Control
            ref={register()}
            type="url"
            id="discord"
            name="discord"
            placeholder="Example: https://discord.gg/...."
            defaultValue={tournament.discord}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.discord && errors.discord.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>YouTube Video</Form.Label>
          <Form.Control
            ref={register()}
            type="url"
            id="youtube"
            name="youtube"
            placeholder="Example: https://www.youtube.com...."
            defaultValue={tournament.youtube}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.youtube && errors.youtube.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Twitch Channel</Form.Label>
          <Form.Control
            ref={register()}
            type="url"
            id="liveStreamLink"
            name="liveStreamLink"
            placeholder="Example: https://www.twitch.tv...."
            defaultValue={tournament.liveStreamLink}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.discord && errors.discord.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Rules Ghost Slug</Form.Label>
          <Form.Control
            ref={register()}
            type="text"
            id="rulesGhostSlug"
            name="rulesGhostSlug"
            placeholder="Example: tournaments-gg-rules-2"
            defaultValue={tournament.rulesGhostSlug}
            onBlur={handleSubmit(onSubmit)}
          />
          <Form.Control.Feedback type="invalid">{errors.rulesGhostSlug && errors.rulesGhostSlug.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Label>About</Form.Label>
        <RichTextbox
          id="about"
          name="about"
          content={about}
          onBlur={(content) => {
            setAbout(content.target.innerHTML);
            handleSubmit(onSubmit);
          }}
        />

        <Form.Label>Powered By</Form.Label>
        <RichTextbox
          id="poweredBy"
          name="poweredBy"
          content={poweredBy}
          onBlur={(content) => {
            setPoweredBy(content.target.innerHTML);
            handleSubmit(onSubmit);
          }}
        />

        <div>
          <div className="d-flex flex-wrap">
            {/* Add new images to the array */}
            {[
              { name: "Logo", key: "logoImage", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
              { name: "Thumbnail", key: "thumbnailImage", width: 500, height: 300, displayWidth: 250, displayHeight: 150, crop: true },
              { name: "Cover", key: "coverImage", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
              {
                name: "Advertisement 1",
                key: "firstAd",
                width: 500,
                height: 300,
                displayWidth: 250,
                displayHeight: 150,
                crop: true,
                isCheck: true,
              },
              {
                name: "Advertisement 2",
                key: "secondAd",
                width: 500,
                height: 300,
                displayWidth: 250,
                displayHeight: 150,
                crop: true,
                isCheck: true,
              },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop, isCheck }) => (
              <>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />

                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  {isCheck ? (
                    <Form.Switch id={key} label="Replace ad" onChange={(e) => replaceAdFunc(key)} checked={replaceAd[key]} />
                  ) : null}
                  <div
                    className="edit-image-btn"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${tournament[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </>
            ))}
          </div>
        </div>

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default Information;
