import React from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrashAlt,
  faPencilAlt,
  faCheckCircle,
  faLeaf,
  faSun,
  faCannabis,
 faSnowflake,
  faMoon,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const seasonCatagories = {
  Spring: faLeaf,
  Summer: faSun,
  Fall: faCannabis,
  Winter: faSnowflake,
  Offseason: faMoon,
  Other: faQuestion,
};

const SingleSeason = ({ season, onEditSeason, deletingSeason, onRemoveSeason }) => {
  const { _id, name, year, showOnHomepage, category } = season;

  return (
    <Card body>
      <div className="d-flex justify-content-between">
        <h5>
          {name} {year} <FontAwesomeIcon className="ml-3 h-20" icon={seasonCatagories[category]} />
        </h5>
        {showOnHomepage && (
          <small className="text-success">
            <FontAwesomeIcon icon={faCheckCircle} /> Homepage Visible
          </small>
        )}
      </div>

      <Button size="sm" variant="primary" className="space-right" as={Link} to={`/games/seasons/${_id}/leagues`}>
        <FontAwesomeIcon icon={faEye} /> View Leagues
      </Button>
      <Button size="sm" variant="primary" className="space-right" as={Link} to={`/games/seasons/${_id}/tournaments`}>
        <FontAwesomeIcon icon={faEye} /> View Tournments
      </Button>
      <Button size="sm" variant="outline-success" className="space-right" onClick={() => onEditSeason(season)}>
        <FontAwesomeIcon icon={faPencilAlt} /> Edit
      </Button>
      <Button
        size="sm"
        className="space-right"
        variant="outline-danger"
        onClick={() => onRemoveSeason(_id)}
        disabled={deletingSeason === _id}
      >
        {deletingSeason === _id ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <>
            <FontAwesomeIcon icon={faTrashAlt} /> Delete
          </>
        )}
      </Button>
    </Card>
  );
};

export default SingleSeason;
