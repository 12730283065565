import React, { useState } from "react";
import { Card, Nav } from "react-bootstrap";
import General from "../TeamTabsManager/Tabs/General";
import Members from "../TeamTabsManager/Tabs/Members";

const TeamTabsManager = ({ team, setTeam }) => {
  const [activeTab, setActiveTab] = useState("General");

  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "General",
      content: (
        <General team={team} setTeam={setTeam} />
      ),
    },
    {
      name: "Members",
      content: (
        <Members team={team} setTeam={setTeam} />
      ),
    },
    {
      name: "Audit log",
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default TeamTabsManager;
