import React, { useState } from "react";
import Axios from "axios";
import { Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { localeData } from "moment";

const SetPlayers = ({ onClose, teamData = {}, teamSelector, match, updateMatches, league, flag, isScrim, game = null }) => {
  const [loading, setLoading] = useState(false);
  const { _id } = match;
  const data = flag ? match.csgoData : match.lolData;
  const [gameData, setGameData] = useState(!isScrim ? league.game : game);
  const [roles, setRoles] = useState(
    gameData.configuration.playerTypes.map(({ name, optional }) => {
      const existingSetPlayer = data?.players && (data?.players[teamSelector] || []).find(({ role = "" }) => role.includes(name));

      return {
        role: name,
        player: existingSetPlayer ? existingSetPlayer.player : false,
        optional,
      };
    })
  );

  const isAllPlayersSet = () => {
    const requiredRoles = roles.filter(({ optional }) => !optional);

    return requiredRoles.filter(({ player }) => player).length >= requiredRoles.length;
  };

  const onUpdatePlayer = (roleName, newPlayerId) => {
    setRoles((roles) => roles.map((role) => (role.role === roleName ? { ...role, player: newPlayerId } : role)));
  };

  const onSetPlayers = async () => {
    if (!isAllPlayersSet() && !flag) return false;
    setLoading(true);

    try {
      let data;
      if (flag) {
        data = await Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${_id}/setPlayers`, { roles, teamSelector });
      } else {
        data = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/matches/${_id}/setPlayers`, { roles, teamSelector });
      }
      if (isScrim) {
        updateMatches(_id, data.data.newMatch);
      } else {
        updateMatches((matches) => matches.map((m) => (m._id === _id ? data.data.newMatch : m)));
      }
      setLoading(false);
      toast.success("Successfully set players for " + teamData.name);
      onClose();
    } catch (e) {
      toast.error("Could not set players for this match");
      setLoading(false);
    }
  };

  const onSetDefaultPlayers = async () => {
    roles.map(
      (singleRole) =>
        teamData.members.length > 0 &&
        teamData.members.filter(({ player = null, role }) => {
          if (role && role.includes(singleRole.role) && player) {
            onUpdatePlayer(singleRole.role, player._id);
          }
        })
    );
  };

  return (
    <div>
      {roles
        .filter(({ role }) => role.toLowerCase() !== "sub")
        .map(({ icon, role, player, optional }, index) => (
          <Form.Group key={role}>
            <Form.Label>{gameData?.shortName == "CSGO" ? "Player " + (index + 1) : role}</Form.Label>
            <Form.Control
              as="select"
              value={(player?._id ? player._id : player) || "_DEFAULT_"}
              onChange={(e) => onUpdatePlayer(role, e.target.value)}
            >
              {!flag ? (
                <option value="_DEFAULT_" disabled={!optional}>
                  {optional ? "No substitute" : "No Player Selected"}
                </option>
              ) : (
                <option value="null">{optional ? "No substitute" : "No Player Selected"}</option>
              )}

              {teamData?.members.length > 0 &&
                teamData?.members
                  .filter(({ role = "" }) =>  role?.includes("Player"))
                  .map(({ player }) => player && <option value={player._id}>{player.name}</option>)}
            </Form.Control>
          </Form.Group>
        ))}
      <Button onClick={onSetPlayers} variant="success" className="mt-2" disabled={(loading || !isAllPlayersSet()) && !flag}>
        {loading ? <Spinner animation="border" size="sm" /> : "Save"}
      </Button>{" "}
      <Button onClick={onSetDefaultPlayers} variant="success" className="mt-2" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : "Set Default Players"}
      </Button>
    </div>
  );
};

export default SetPlayers;
