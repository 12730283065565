
import React, {useEffect } from 'react';
import useState from 'react-usestateref' 
import { toast } from "react-toastify";
import {  Row, Col,Card,ListGroup,Form,Button } from "react-bootstrap";
import Axios from "axios";
const Rosters = ({tournament}) => {
  console.log("Tournment--->",tournament);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [roles,setRoles] = useState([..._roles]);
  const [fieldChanged , setFieldChanged] = useState(false);
  const [updatedPlayers,setUpdatedPlayers,updatedPlayerRef] = useState({});
  
  useEffect(() => {
    getParticipants();
    if(tournament.numberOfSubs && tournament.numberOfSubs>0){
      for (let i = 0; i < tournament.numberOfSubs; i++) roles.push("SUB " + (i + 1));
      setRoles([...roles]);
    }
  }, []);

  useEffect(() => {
     tournament.game?.configuration?.playerTypes && setRoles(tournament.game?.configuration?.playerTypes.map(player => player.name))
  }, [tournament]);


  const handleSelectTeam = (team)=>{
    if(fieldChanged){
      const saveChanges = window.confirm("You have unsaved changes. Don't you want to save?");
      if(saveChanges){
        setFieldChanged(false);
        handleUpdateTeam();
      }
      else{
        setFieldChanged(false);
        setUpdatedPlayers({});
        setSelectedTeam(team);
      }
    }
    else{
      setSelectedTeam(team);
    }
  }

  const handleUpdateTeam = ()=>{
    Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}/teams/${selectedTeam.team._id}/update-roles`,updatedPlayers).then((response) => {
      setLoading(true);
      setUpdatedPlayers({});
      setFieldChanged(false);
      getParticipants();
      toast.success("Team Updated!");
    })
    .catch((error)=>{
      toast.error(`Error occured: ${error?.response?.data?.msg}`);
      setLoading(false);
    })
  }

  const getParticipants = ()=>{
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}/participants`).then(({ data }) => {
      setLoading(false);
      if(data?.teams?.length>0){
        setTeams(data.teams);
        setSelectedTeam(data.teams[0]);
      } 
    }).catch((err)=>{
      setLoading(false);
    })
   }

 const handlePlayerRoleChange = (value,player,playerType)=>{
  setFieldChanged(true);
  // for first time change in player position
  if(!updatedPlayers.members){
    console.log("first time creation");
    const members = selectedTeam.selectedPlayers.filter((pl)=>(pl.player_id)).map((player)=>{
      return(
        {
          role:player.role,
          player_id:player.player_id._id
        }
      )
    })

    const subPlayers = selectedTeam.subPlayers.filter((pl)=>(pl.player_id)).map((player)=>{
      return(
        {
          role:player.role,
          player_id:player.player_id._id
        }
      )
    });
    setUpdatedPlayers({members:[...members],subPlayers:[...subPlayers]})
  }
  // set the values to list which we going to update
  let currentPlayers = updatedPlayerRef.current;
    
  // if choosen from players and value no sub
  if((playerType === 'player') && !value.includes("SUB")){
    const playerIndex = currentPlayers.members.findIndex((pl)=>(pl.player_id===player.player_id._id));
   if(playerIndex!==-1){ currentPlayers.members[playerIndex].role = value;}
   else{
      const playerObj = {
          role:value,
          player_id:player.player_id._id
      }
      currentPlayers.members.push(playerObj);
    }
    if(value==="Select role" && playerIndex!==-1){
      currentPlayers = {
        subPlayers:currentPlayers.subPlayers,
        members:currentPlayers.members.filter((pl)=>(pl.player_id!==player.player_id._id))
      }
    }
    setUpdatedPlayers(currentPlayers);
  }

  // if choosen from subplayers and value no sub
  if((playerType === 'subplayer') && !value.includes("SUB")){
    
    const playerIndex = currentPlayers.members.findIndex((pl)=>(pl.player_id===player.player_id._id));
    const playerIndexSub = currentPlayers.subPlayers.findIndex((pl)=>(pl.player_id===player.player_id._id));

    if(value==="Select role"){
      if( playerIndexSub!==-1){
        currentPlayers = {
          subPlayers:currentPlayers.subPlayers.filter((pl)=>(pl.player_id!==player.player_id._id)),
          members:currentPlayers.members
        }
      }
      if( playerIndex!==-1){
        currentPlayers = {
          subPlayers:currentPlayers.subPlayers,
          members:currentPlayers.members.filter((pl)=>(pl.player_id!==player.player_id._id))
        }
      }
      setUpdatedPlayers(currentPlayers);
      return false;
    }

    if(playerIndex!==-1){
      currentPlayers.members[playerIndex].role = value;
    }
    else{
      const playerObj = {
        role:value,
        player_id:player.player_id._id
      }
      currentPlayers.members.push(playerObj);
    }
    // check if it exits in sub players before

    if(playerIndexSub!==-1){
      currentPlayers = {
        members:currentPlayers.members,
        subPlayers: currentPlayers.subPlayers.filter((pl)=>(pl.player_id!==player.player_id._id))
      } 
    }
     
    
    setUpdatedPlayers(currentPlayers);
  }
  // if choosen from members value no sub
  else if((playerType === 'member') && !value.includes("SUB")){
    const playerIndex = currentPlayers.members.findIndex((pl)=>(pl.player_id===player.player._id));
    const playerIndexSub = currentPlayers.subPlayers.findIndex((pl)=>(pl.player_id===player.player._id));
    // if value is no role
    if(value==="Select role"){
      if( playerIndex!==-1){
        currentPlayers = {
          subPlayers:currentPlayers.subPlayers,
          members:currentPlayers.members.filter((pl)=>(pl.player_id!==player.player._id))
        }
      }
      if( playerIndexSub!==-1){
        currentPlayers = {
          subPlayers:currentPlayers.subPlayers.filter((pl)=>(pl.player_id!==player.player._id)),
          members:currentPlayers.members
        }
      }
      setUpdatedPlayers(currentPlayers);
      return false
    }
    
    // value other than no role
    if(playerIndex!==-1){
      currentPlayers.members[playerIndex].role = value;
    }
    else{
      const playerObj = {
        role:value,
        player_id:player.player._id
      }
      currentPlayers.members.push(playerObj);
      
    }
    setUpdatedPlayers(currentPlayers);
  }

  // if choosen from players value has sub
  else if((playerType === 'player') && value.includes("SUB")){
    const subPlayerIndex = currentPlayers.subPlayers.findIndex((pl)=>(pl.player_id===player.player_id._id));
    const playerIndex = currentPlayers.members.findIndex((pl)=>(pl.player_id===player.player_id._id));
    
    if(subPlayerIndex!==-1){
      currentPlayers.subPlayers[subPlayerIndex].role = value;
    }
    else{
      const playerObj = {
        role:value,
        player_id:player.player_id._id
      }
      currentPlayers.subPlayers.push(playerObj);
    }

    // check if it exits in players before

    if(playerIndex!==-1){
      currentPlayers = {
        subPlayers:currentPlayers.subPlayers,
        members: currentPlayers.members.filter((pl)=>(pl.player_id!==player.player_id._id))
      } 
    }
    setUpdatedPlayers(currentPlayers);
  }
  // if player is sublayer and has sub
  else if((playerType === 'subplayer') && value.includes("SUB")){
    const playerIndex = currentPlayers.subPlayers.findIndex((pl)=>(pl.player_id===player.player_id._id));
    currentPlayers.subPlayers[playerIndex].role = value;
    setUpdatedPlayers(currentPlayers);
  }

  // if player is member and has sub
  else if((playerType === 'member') && value.includes("SUB")){
    const subPlayerIndex = currentPlayers.subPlayers.findIndex((pl)=>(pl.player_id===player.player._id));
    const playerIndex = currentPlayers.members.findIndex((pl)=>(pl.player_id===player.player._id));

    if(subPlayerIndex!==-1){
      currentPlayers.subPlayers[subPlayerIndex].role = value;
    }
    else{
      const playerObj = {
        role:value,
        player_id:player.player._id
      }
      currentPlayers.subPlayers.push(playerObj);
    }
    // if it was earlier in members array
    // remove from members
    if(playerIndex!==-1){
      currentPlayers = {
        subPlayers:currentPlayers.subPlayers,
        members: currentPlayers.members.filter((pl)=>(pl.player_id!==player.player._id))
      } 
    }
    setUpdatedPlayers(currentPlayers);
  } 
 }




  return (
    <div className="relative">
      {loading && <div className="abs-loader">Processing...</div>}
      <Row>
        <Col md={6}>
          <h5>Teams</h5>
          <Card className="roster-card">
            <Card.Body>
              <ListGroup variant="flush">
                {teams.length > 0 &&
                  teams.map((_team) => (
                    <ListGroup.Item
                      className="roster-team"
                      key={_team.team?._id}
                      active={_team.team?._id === selectedTeam?.team?._id}
                      action
                      onClick={() => {
                        handleSelectTeam(_team);
                      }}
                    >
                      {_team.team?.name}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <h5>Players</h5>
          <Card className="roster-card">
            <Card.Body>
              <>
                {/* selected Players  */}
                {selectedTeam?.selectedPlayers?.map((teamPlayer) =>
                  teamPlayer.player_id ? (
                    <Row key={teamPlayer?.player_id?._id}>
                      <Col md={7}>{teamPlayer?.player_id?.name}</Col>
                      <Col md={5}>
                        <Form.Group>
                          <Form.Control
                            name="playerRole"
                            placeholder="Select role"
                            as="select"
                            onChange={(e) => handlePlayerRoleChange(e.target.value, teamPlayer, "player")}
                          >
                            <option value="Select role">Select Role</option>
                            {roles.map((role) => (
                              <option selected={teamPlayer.role === role} value={role}>
                                {role}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null
                )}
                {/* sub players  */}
                {selectedTeam?.subPlayers?.map((teamPlayer) =>
                  teamPlayer.player_id ? (
                    <Row key={teamPlayer?.player_id?._id}>
                      <Col md={7}>{teamPlayer?.player_id?.name}</Col>
                      <Col md={5}>
                        <Form.Group>
                          <Form.Control
                            name="playerRole"
                            placeholder="Select role"
                            as="select"
                            onChange={(e) => handlePlayerRoleChange(e.target.value, teamPlayer, "subplayer")}
                          >
                            <option value="Select role">Select Role</option>
                            {roles.map((role) => (
                              <option selected={teamPlayer.role === role} value={role}>
                                {role}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null
                )}

                {/* other team members  */}
                {selectedTeam?.team?.members
                  .filter(
                    (member) =>
                      member.player &&
                      !selectedTeam?.selectedPlayers
                        ?.concat(selectedTeam.subPlayers)
                        .some((player) => member.player._id === player?.player_id?._id)
                  )
                  .map((teamPlayer) => (
                    <Row key={teamPlayer?.player?._id}>
                      <Col md={7}>{teamPlayer?.player?.name}</Col>
                      <Col md={5}>
                        <Form.Group>
                          <Form.Control
                            name="playerRole"
                            placeholder="Select role"
                            as="select"
                            onChange={(e) => handlePlayerRoleChange(e.target.value, teamPlayer, "member")}
                          >
                            <option value="Select role">Select Role</option>
                            {roles.map((role) => (
                              <option selected={teamPlayer.role === role} value={role}>
                                {role}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="success" disabled={!updatedPlayers.members} onClick={handleUpdateTeam}>
            Update Team
          </Button>
        </Col>
      </Row>
    </div>
  );
}
 
export default Rosters;

const _roles = [
  "TOP",
  "JUNGLE",
  "MID",
  "BOT",
  "SUPPORT"
]