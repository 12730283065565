import React, { useState, useEffect, createRef } from "react";
import { Modal, Button } from "react-bootstrap";
import StepMapSelection from "./StepMapSelection";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

const MatchVetoModalCSGO = ({ show, onHide, match, vetoInProgress, league }) => {
  const [submitError, setSubmitError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [vetoSteps, setVetoSteps] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vetoStarted, setVetoStarted] = useState(false);
  useEffect(() => {
    if (match) {
      Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/veto`)
        .then(({ data }) => {
          const messageError = data.steps.map((data) => ({ id: data.no, error: false }));
          setSubmitError(messageError);
          setSubmit(!data.steps.every((el) => el.map !== null));
          setVetoSteps(data.steps);
          setLoading(false);
        })
        .catch((err) => {
          toast.error("There was a problem in getting veto steps");
        });
      if (match.veto && match.veto.vetoStartTime) {
        let startTime = new Date(new Date(match.datetime).getTime() - match.veto.vetoStartTime * 1000).toISOString();
        if (moment(startTime).diff(moment(), "seconds") < 0) {
          setVetoStarted(true);
        }
      }
    }
  }, [match, show]);

  useEffect(() => {
    if (submitError) {
      const error = submitError.findIndex((err) => err.error);
      error === -1 ? setSubmit(false) : setSubmit(true);
    }
  }, [submitError]);

  const onError = (data) => {
    let _submitError = [...submitError];
    _submitError = _submitError.map((err) => {
      if (err.id === data.id) {
        err.error = data.error;
        return err;
      }
      return err;
    });
    setSubmitError(_submitError);
  };
  const onResetVeto = () => {
    let confirmMessage = `Are you sure you want to reset veto? ${
      match.status != "upcoming"
        ? `This match is already ${match.status} and resetting veto will remove all data.`
        : vetoStarted
        ? "The veto is already in progress and resetting veto wll remove all data"
        : ""
    }`;
    if (window.confirm(confirmMessage)) {
      Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/veto/resetVeto`)
        .then(({ data }) => {
          setVetoSteps(data.updatedVeto.steps);
          toast.success(`Successfully reset veto`);
        })
        .catch((err) => {
          toast.error("There was a problem in getting veto steps");
        });
    }
  };

  const onSubmit = () => {
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/veto/${match._id}/updateVeto`, { steps: vetoSteps })
      .then(({ data }) => {
        toast.success(data.msg);
        setVetoSteps(data.updatedVeto.steps);
        // onHide();
      })
      .catch((err) => {
        const msg = err.response.data ? err.response.data.msg : "There was a problem updating veto steps";
        toast.error(msg);
      });
  };
  return (
    <>
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Manually Set Veto Steps {vetoInProgress} </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!loading && (
            <StepMapSelection
              match={match}
              onHide={onHide}
              vetoData={vetoSteps}
              setSubmitError={onError}
              serverLocation = {league.csgoServerLocation || 'stockholm'}
              setUpdatedSteps={(data) => {
                debugger
                setVetoSteps(data);
                setSubmit(!data.every((el) => el.map !== null));
              }}
            />
          )}

          {/* {vetoInProgress && (
            <div className="text-center text-success">
              <h4> Veto is in Progress</h4>
            </div>
          )} */}
        </Modal.Body>

        <Modal.Footer className="d-block p-3">
          <div className="d-flex justify-content-end">
            <Button type="button" className="mr-2" size="sm" variant="success" onClick={onResetVeto}>
              Reset Veto
            </Button>
            <Button className="mr-2" type="button" size="sm" variant="secondary" onClick={() => onHide()}>
              Cancel
            </Button>
            <Button type="submit" size="sm" variant="success" disabled={submit} onClick={onSubmit}>
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MatchVetoModalCSGO;
