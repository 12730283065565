import React from "react";
import { Card, Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";


const SingleLeague = ({ league, onEditLeague, deletingLeague, onRemoveLeague, creationPermission }) => {
  const { _id, name, thumbnailImage } = league;

  return (
    <Card>
      <Card.Img variant="top" src={thumbnailImage} height={200} />
      <Card.Body style={{ backgroundColor: league.hideLeague ? "#e5e5e5" : "" }}>
        {league.hideLeague && <p className="hiddenText">HIDDEN</p>}
        <h5>{name}</h5>
        <div className="displayOptions">
          <Button size="sm" variant="primary" className="space-right" as={Link} to={`/games/leagues/${_id}/matches`}>
            <FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
          </Button>
          <Button size="sm" variant="outline-success" className="space-right" onClick={() => onEditLeague(league)}>
            <FontAwesomeIcon icon={faPencilAlt} /> Edit name
          </Button>
          <Button
            size="sm"
            className="space-right"
            variant="outline-danger"
            onClick={() => onRemoveLeague(_id)}
            disabled={deletingLeague === _id || !creationPermission}
          >
            {deletingLeague === _id ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </>
            )}
          </Button>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{league.showOnDate && `${moment(league.showOnDate).format("HH:mm")} ${moment.tz(league.timezone).format("z")} - ${moment(league.showOnDate).format("Do MMM YYYY")}`}</Tooltip>}>
            {league.showOnDate ? <p className="showDate">{league.showOnDate && `${moment(league.showOnDate).format("HH:mm")} ${moment.tz(league.timezone).format("z")} - ${moment(league.showOnDate).format("Do MMM YYYY")}`}</p> : <></>}
          </OverlayTrigger>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SingleLeague;
